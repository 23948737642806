window.SignIn = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#new_user").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          email: true
        },
        "user[password]": {
          required: true
        }
      },
      errorPlacement: (error, element) => {
        if(element.attr('name') === 'user[password]')
          error.insertAfter(element.next());
        else
          error.insertAfter(element);
      }
    });
  }
};
