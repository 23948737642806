import {initSelect2OnElement, initMultiSelect2} from '../select2'

window.Companies = {
  init() {
    this.initSelect2();
  },

  initSelect2() {
    initSelect2OnElement('#location');
  },
}
