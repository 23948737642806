function createSubscriptionOnSubmit(event, cardNumber, stripe) {
    let $submitButton = $('#btn-pay-for-subscription');
    $submitButton.click(event => {
        event.preventDefault();
        if ($('#subscription-form :radio:checked').length < 1) {
            const submitText = $submitButton.text();
            $submitButton.prop('disabled', true).text('Processing...')
            stripe.createToken(cardNumber).then(result => {
                if (result.error) {
                    let errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    $submitButton.prop('disabled', false).text(submitText);
                } else {
                    if(result.token && result.token.id){
                        append_stripe_token_input(result.token.id);
                    }
                    $.ajax({
                        type: 'POST',
                        url: '/subscriptions',
                        data: $('#subscription-form').serialize()
                    }).then(
                        function (data) {
                            if (data.client_secret) {
                                stripe
                                    .confirmCardPayment(data.client_secret)
                                    .then(function (result) {
                                        if (result.error) {
                                            alert("Verification failed");
                                            window.location = '/account_management?active_tab=plans'
                                        } else {
                                            window.location = '/account_management?active_tab=plans'
                                        }
                                    });
                            } else {
                                window.location = '/account_management?active_tab=plans'
                            }
                        });
                }
            });
        } else {
            $submitButton.prop('disabled', true).text('Processing...')
            $.ajax({
                type: 'POST',
                url: '/subscriptions',
                data: $('#subscription-form').serialize()
            }).then(
                function (data) {
                    if (data.client_secret) {
                        stripe
                            .confirmCardPayment(data.client_secret)
                            .then(function (result) {
                                console.log(result);
                                if (result.error) {
                                    alert("Verification failed");
                                    window.location = '/account_management?active_tab=plans'
                                } else {
                                    window.location = '/account_management?active_tab=plans'
                                }
                            });
                    } else {
                        window.location = '/account_management?active_tab=plans'
                    }
                });
        }
    });
}

window.CalculateTax = {
    init(stripePublicKey) {
        const stripe = Stripe(stripePublicKey);
        const elements = stripe.elements();
        let $planPrice = $('#plan-price').attr('value');

        let cardNumber = elements.create('cardNumber', {showIcon: true});
        cardNumber.mount('#card-number');

        let cardExpiry = elements.create('cardExpiry');
        cardExpiry.mount('#card-expiry');

        let cardCvc = elements.create('cardCvc');
        cardCvc.mount('#card-cvc');

        $('.text-danger').text('');

        let $submitButton = $('#btn-add-card');

        $submitButton.click(event => {
            $('.text-danger').text('');
            event.preventDefault();

            if (!$('#address_line1').val() || !$('#address_city').val() || !$('#address_postal_code').val()) {
                return false;
            }

            let country = $('#country-drop-down').val();
            if (!country || country === '0') {
                return false;
            }
            if ($('#subscription-form :radio:checked').length < 1) {
                var cardName = $('#card_name').val();
                if (!cardName) {
                    return false;
                }
            }
            $submitButton.prop('disabled', true).text('Processing...')

            $.ajax({
                type: 'POST',
                url: '/calculate_tax',
                data: $('#subscription-form').serialize(),
                dataType: 'json',
                async: false,
                success: function (data) {
                    if (!data.success) {
                        window.location.reload();
                    } else {
                        let totalPrice = parseFloat($planPrice) + data.vat_tax
                        $('#vat-tax-price').text(`VAT (${data.vat_percentage}%)`)
                        $('#total-price').text(`€${totalPrice}/m`)
                        $('#sub-total').text(`€${data.vat_tax}/m`)
                        $('#invoice').removeClass('hidden')
                        $('#vat-heading').text(`Including VAT (${data.vat_percentage}%)`)
                        $('#including-vat').text(`€${totalPrice}/m`)

                        // $('#billing-address-form').hide();
                        $('#add-card-heading').text('Upgrade Your Plan')
                        $('#apply-discount-button').removeClass('hidden')
                        $('#btn-add-card').replaceWith('<button name="button" type="submit" class="btn blue-lg-btn w-auto px-4" id="btn-pay-for-subscription">Pay now</button>')
                        $('#btn-pay-for-subscription').prop('disabled', false).text(`Pay €${(parseFloat($planPrice) + parseFloat(data.vat_tax.toFixed(2))).toFixed(2)}`);
                        // create stripe subscription here
                        createSubscriptionOnSubmit(event, cardNumber, stripe)
                    }
                }
            })
        });
    }
}


window.DiscountCode = {
    init() {

        let $applyButton = $('#btn-apply-discount');
        let $discountCodeInput = $('#discount-code');
        let $planPrice = $('#plan-price').attr('value');
        let error = $('#invalid-code')
        $applyButton.click(() => {
            if ($discountCodeInput.val() != '') {
                $.ajax({
                    url: `/apply_discount`,
                    data: {code: $discountCodeInput.val()},
                    dataType: 'json',
                    async: false,
                    success: function (data) {
                        if (!data.success) {
                            $('#invalid-code').text('We are sorry, but this is not a valid discount code')
                            window.DiscountCode.reset($planPrice, 'red', true)
                            $('#btn-pay-for-subscription').prop('disabled', true).text(`Pay €${(parseFloat(totalPrice) + parseFloat(vat.toFixed(2))).toFixed(2)}`);
                        } else {
                            let discountPrice = $planPrice * data.coupon.percent_off / 100
                            let totalPrice = $planPrice - discountPrice
                            $('#coupon_id').val(data.coupon.id)
                            $('#code').val($('#discount-code').val())
                            $('#discount-percentage').text(` ${data.coupon.percent_off}%`)
                            $('#discount-total').text(`- €${discountPrice}`)
                            window.DiscountCode.reset(totalPrice, 'green', false)
                            $('#invalid-code').text('Discount has been applied')
                            $('#btn-pay-for-subscription').prop('disabled', false)
                            window.DiscountCode.discountDuration(data.coupon.duration, data.coupon.duration_in_months)
                        }
                    }
                });
            }
        });
    },
    reset(totalPrice, color, showDiscount) {
        $('#invalid-code').css("color", color);
        $('#total-price').text(`€${totalPrice}/m`);
        var vat
        vat = 0.23 * totalPrice
        $('#btn-pay-for-subscription').text(`Pay €${(parseFloat(totalPrice) + parseFloat(vat.toFixed(2))).toFixed(2)}`);
        $('#discount').prop("hidden", showDiscount);
        $('#discount-note').prop("hidden", showDiscount);

    },
    discountDuration(duration, durationInMonths = 1) {
        var options = {year: 'numeric', month: 'long', day: 'numeric'};
        let date = new Date();
        date.setMonth(date.getMonth() + durationInMonths);
        date = date.toLocaleDateString("en-US", options)
        if (duration === 'once') {
            $('#discount-duration').text(`until ${date}`)
        } else if (duration === 'repeating') {
            $('#discount-duration').text(`until ${date} and will renew again on that date`)
        } else if (duration === 'forever') {
            $('#discount-duration').text(`forever`)
        }

    }
}

function append_stripe_token_input(token) {
    $('<input>').attr({
        type: 'hidden',
        id: 'stripe_token',
        name: 'stripe_token',
        value: token
    }).appendTo('#subscription-form');
}

$('#discount-code').on('keyup', function () {
    let totalPrice = $('#plan-price').attr('value');
    var vat
    vat = 0.23 * totalPrice
    let val = $('#discount-code').val();
    if (val == '') {
        $('#invalid-code').text('')
        $('#btn-pay-for-subscription').prop('disabled', false).text(`Pay €${(parseFloat(totalPrice) + parseFloat(vat.toFixed(2))).toFixed(2)}`);
    }
})
