import {initMultiSelect2} from "./select2"

const AUTH_TOKEN=$('meta[name="csrf-token"]').attr('content');

window.ProductsNServices = {
  init() {
    this.initDropZone();
    this.handleAddProduct();
    this.handleClickOnAddAnotherProduct();
    this.validateForm();
    this.hanldeClickOnRemoveLogo();
  },

  initDropZone() {
    const self = this
    const submitBtn = $('.submit-product-btn')
    const addAnotherBtn = $('#submit-product-service-form')
    $(".dropzone").not(".dz-clickable").each((index, element) => {
      $(element).dropzone({
        url: "/products/upload_product_images",
        previewsContainer: $(element).siblings('.drop-zone-preview')[0],
        previewTemplate: document.querySelector('.drop-zone-custom').innerHTML,
        uploadMultiple: false,
        thumbnailWidth: 75,
        thumbnailHeight: 75,
        acceptedFiles: ".jpg,.jpeg,.gif,.png,.svg,.tiff,.bmp,.webp",
        init: function() {
          this.on("error", function(file) {
            this.removeFile(file);
            $('.dropzone').after(
              '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">'+
                  '<button type="button" class="close" ' +
                    'data-dismiss="alert" aria-hidden="true">' +
                      '&times;' +
                  '</button>' +
                  'Unsupported file format' +
              '</div>'
            );
            $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function() {
              $("#rr-success-alert").slideUp(500);
            });
            setTimeout(function() {
              $("#rr-success-alert").remove();
            }, 6000);
          });
        },
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        success: (file, response) => {
          if (response.err_msg == '' || response.err_msg == undefined) {
            const productImagesElement = $("#product-images");
            $(file.previewElement).find('.remove-logo').attr('data-image-id', response.id);
            productImagesElement.append(`<input type='hidden' value='${response.id}' name='product[product_image_ids][]'>`);
            self.hanldeClickOnRemoveLogo();
          } else {
            alert(response.err_msg);
            file.previewElement.remove();
          }
          submitBtn.removeAttr('disabled');
          addAnotherBtn.removeClass('disabled');
        },
        sending: () => {
          submitBtn.attr('disabled','disabled')
          addAnotherBtn.addClass('disabled')
        }
      });
    });
  },

  handleInsertCocoonEle() {
    this.initDropZone();
    initMultiSelect2();
  },

  handleAddProduct() {
    $('#products-wrapper').on('cocoon:after-insert', this.handleInsertCocoonEle.bind(this));
  },

  handleRemoveImage(e) {
    const imageId = e.target.dataset.imageId
    $(`#product-images :input[value="${imageId}"]`).remove()
    $(e.target).parents('.dz-preview').remove()
  },

  hanldeClickOnRemoveLogo() {
    $(".remove-logo").on('click', this.handleRemoveImage.bind(this))
  },

  turnOnAddAnotherProduct() {
    $("#add_another").val(true)
    $("#products-services-form").submit()
  },

  handleClickOnAddAnotherProduct() {
    $("#submit-product-service-form").on('click', this.turnOnAddAnotherProduct)
  },

  validateForm() {
    $("#products-services-form").validate({
      errorPlacement: function (error, element) {
        if(element.hasClass('multi-select2') && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        }
        else {error.insertAfter(element)}
      }
    });

    $.validator.addClassRules("product-name", { required: true});

    $.validator.addClassRules("product-category", { required: true});

    $.validator.addClassRules("product-description", {
      required: true
    });

    $.validator.addMethod("required",$.validator.methods.required, "This field is required.")
  }
};
