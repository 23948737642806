window.Teams = {
    init() {
        this.handleShowMore('.show-more-deals', '.deals-card');
        this.handleShowMore('.show-more-chats', '.chats-card');
    },

    handleShowMore(showMoreElementId, elementToShowMoreId) {
        document.addEventListener('DOMContentLoaded', () => {
            const showMoreButton = document.querySelector(showMoreElementId);
            const itemList = document.querySelector(elementToShowMoreId);

            if (itemList) {
                Array.from(itemList.children).forEach((item, index) => {
                    if (index > 2) {
                        item.style.display = 'none';
                    } else {
                        item.style.display = 'block';
                    }
                });

                const totalItems = parseInt(itemList.dataset.totalItems, 10);
                let currentVisibleItems = Array.from(itemList.children).filter(e => window.getComputedStyle(e).display === 'block').length;

                if (showMoreButton) {
                    showMoreButton.addEventListener('click', () => {
                        const itemsToShow = Array.from(itemList.children)
                            .slice(currentVisibleItems, currentVisibleItems + 2);

                        itemsToShow.forEach(item => {
                            item.style.display = 'block';
                        });

                        currentVisibleItems += itemsToShow.length;
                        if (currentVisibleItems >= totalItems) {
                            showMoreButton.style.display = 'none';
                        }
                    });
                }
            }
        });
    }
}
