window.CertificatesNAwards = {
  init() {
    this.validateForm();
    this.handleOtherCertificates();
    this.showTitleOnLoad();
  },

  validateForm() {
    $("#certificates_awards_form").validate({
    });

    $.validator.addClassRules("certificate-id", { requiredBaseOnDescription: "certificate-description"});

    $.validator.addClassRules("certificate-description", { maxlength: 200 });

    $.validator.addClassRules("award-name", {
      maxlength: 200,
      requiredBaseOnDescription: "award-description"
    });

    $.validator.addClassRules("award-description", { maxlength: 200 });
    $.validator.addClassRules("certificate-title", { titleForOtherCert: "certificate-id"})

    $.validator.addMethod ("requiredBaseOnDescription", function (value, element, params) {
      descriptionClass = params
      desciptionValue = $(element).parent().parent().find(`.${descriptionClass}`).val().trim()
      return (value.trim() || !desciptionValue )
    }, "This field is required.");

    $.validator.addMethod("maxlength", $.validator.methods.maxlength,
      $.validator.format("Please enter no more than {0} characters."));

    $.validator.addMethod ("titleForOtherCert", function (value, element, params) {
      if (value != "") {
        return true;
      }
      if ($(element).parent().hasClass('hidden')) {
        return true;
      }
      return false;
    }, "This field is required.");
  },

  showTitleOnLoad() {
    $(".certificate-title").each(function() {
      if($(this).val() != "") {
        $(this).parent().removeClass("hidden");
      }
    });
  },

  handleOtherCertificates() {
    $(document).on('change', ".certificate-id", function () {
      var selectedText = $(this).find("option:selected").text();
      var titleGroup = $(this).parent().next();
      var titleText = $(titleGroup).find("input");
      if (['Other', 'Others'].indexOf(selectedText) > -1) {
        $(titleGroup).removeClass('hidden');
        $(titleText).val("");
        $(titleText).focus();
      } else {
        $(titleGroup).addClass('hidden');
        $(titleText).val("");
      }
    });
  }
};
