const { ajax } = require("jquery");

window.MyCompany = {
  init() {
    this.handleVerificationStatusOnClick();
    this.handlePublicProductOnClick();
    this.handlePublicCertificateOnClick();
    this.hanldePublicReviewsOnClick();
  },

  handleVerificationStatusOnClick() {
    $(".vertification-status").on('click', this.handleVerifyCompany.bind(this))
  },

  handleVerifyCompany(e) {
    if (e.target.dataset.verificationStatus !== "not_verified") return

    $.ajax({
      url: `/my_company/${e.target.dataset.companyId}`,
      type: 'PUT',
      dataType: 'json',
      data: {
        company: {
          verification_status: "in_progress"
        }
      },
      success: (data) => {
        if (data) {
          if (data.error) {
            alert(data.error)
          }
          e.target.textContent = "Verification in progress"
          e.target.dataset.verificationStatus = data.verification_status
        }
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  handlePublicProductOnClick() {
    $(".toggle-public-product").on('change', (event) => {
      this.handlePublicItem(event, 'product');
    })
  },

  handlePublicItem(e, item) {
    let params;
    const isPublic = JSON.parse(e.target.value)
    switch (item) {
      case 'product':
        params = {
          company: {
            products_attributes: {
              id: e.target.dataset.productId,
              is_public: !isPublic
            }
          }
        }
        break;
      case 'certificate':
        params = {
          company: {
            company_certificates_attributes: {
              id: e.target.dataset.certificateId,
              is_public: !isPublic
            }
          }
        }
        break;
      case 'reviews':
        params = {
          company: {
            company_detail_attributes: {
              id: e.target.dataset.companyDetailId,
              hide_reviews: isPublic
            }
          }
        }
        break;
    }
    $.ajax({
      url: `/my_company/${e.target.dataset.companyId}`,
      type: 'PUT',
      dataType: 'json',
      data: params,
      success: (data) => {
        if (data) {
          if (data.error) {
            e.target.checked = isPublic
            e.target.value = isPublic
            alert(data.error)
          }
          e.target.checked = !isPublic
          e.target.value = !isPublic
        }
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  handlePublicCertificateOnClick() {
    $(".toggle-public-certificate").on('click',(event) => {
      this.handlePublicItem(event, 'certificate');
    })
  },

  hanldePublicReviewsOnClick() {
    $("#toggle-public-reviews").on('click', (event) => {
      this.handlePublicItem(event, 'reviews');
    })
  }

};
