window.LiveDeals = {
  init() {
    this.bindInterestDealBtn();
  },

  bindInterestDealBtn() {
    $('.interest-deal-btn').click((event) => {
      $(event.target).addClass('disabled')
    })
  }
}
