export const manualFileUploaded = function(e, previewElement = '#logo-reviewer', isShowText = false) {
  const inputTarget = e.target;
  if (inputTarget && inputTarget.files && inputTarget.files[0]) {
    const reader = new FileReader;
    reader.onload = function(evt) {
      const reviewerEle = $(previewElement);
      if (!isShowText) {
        reviewerEle.attr('src', evt.target.result);
        reviewerEle.data( "manualLogo", true );
      }
    };
    if (inputTarget.files[0].size > 50000000) {
      alert('The maximum size of file is 50mb')
    } else {
      reader.readAsDataURL(inputTarget.files[0]);
      if (isShowText) {
        $(previewElement).text(inputTarget.files[0].name)
      }
    }
  }
}
