window.CompanyReviews = {
  init() {
    this.handleReportReviewOnClick();
  },

  handleReportReviewOnClick() {
    $(".report-review").on('click',
      this.handleReportReview.bind(this)
    )
  },

  handleReportReview(e) {
    $.ajax({
      url: `/reviews/${e.target.dataset.reviewId}`,
      type: 'PUT',
      dataType: 'json',
      data: {
        review:
        {
          is_reported: true
        }
      },
      success: (data) => {
        if (data) {
          if (data.error) {
            alert(data.error)
          }
          $(e.target).removeClass("report-review btn-outline-picton-blue")
          e.target.textContent = "Reported"
        }
      },
      failure: (error) => {
        alert(error)
      }
    });
  }
};
