window.AppNotifications = {
  init() {
    this.loadMoreNotificationsOnScroll();
  },

  loadMoreNotificationsOnScroll() {
    $(window).scroll(() => {
      if ($(window).height() + $(window).scrollTop() >= $(document).height() - 645) {
        let url = $('.notifications-list-paging').find("li.page-item > a.page-link[rel='next']").attr('href');
        $('.notifications-list-paging').remove();
        if (url) {
          $.getScript(url);
        }
      }
    })
  }
};
