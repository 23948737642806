import Talk from 'talkjs'
import getUserLocale from "get-user-locale";

window.Chat = {
    init(guest_mode = true) {
        Talk.ready.then(function () {
            talk_me['locale'] = getUserLocale();
            var me = new Talk.User(talk_me);
            const talkSession = new Talk.Session({
                appId: talk_app_id,
                me: me,
                signature: talkjs_signature
            })

            if (window.inbox) {
                window.inbox.destroy();
            }

            let conversationIdElement = document.getElementById("conversation_id");
            let archivedElement = document.getElementById("archived");

            if (archivedElement) {
                let archived = archivedElement.value === "true";
                const inbox = talkSession.createChatbox({
                    showFeedHeader: true,
                    showTranslationToggle: true,
                    showChatHeader: false,
                    messageField: {
                        visible: !archived
                    }
                });

                if (conversationIdElement) {
                    let conversationId = conversationIdElement.value || '';
                    let conversation = talkSession.getOrCreateConversation(conversationId);
                    inbox.select(conversation, {asGuest: guest_mode});
                }
                inbox.mount(document.getElementById("talkjs-container"));
                Chat.handleSentMessages(inbox);
                Chat.handleMessageActions(inbox);
            }
            Chat.handleUnreads(talkSession);
        });
    },

    handleUnreads(session) {
        session.unreads.onChange(unreadConversations => {
            unreadConversations.sort((a, b) => {
                return a.lastMessage.timestamp - b.lastMessage.timestamp;
            });

            unreadConversations.forEach(function (unreadConversation) {
                if (unreadConversation.lastMessage.origin !== 'rest' ||
                    unreadConversation.lastMessage.custom['message_type'] != null) {
                    var conversationId = unreadConversation.conversation.id;
                    var conversationPreview = $("#conversation-preview-" + conversationId);
                    var sideBarUnreadMessageBadge = $("#sidebar-unread-message-badge-" + conversationId);
                    var unreadMessageBadge = $("#unread-message-badge-" + conversationId);
                    var lastMessageText = $("#last-message-" + conversationId);
                    var lastUpdate = $("#last-update-" + conversationId);

                    if (unreadMessageBadge.length) {
                        sideBarUnreadMessageBadge.show();
                        unreadMessageBadge.show();

                        switch (unreadConversation.lastMessage.type) {
                            case "text":
                                lastMessageText.text(unreadConversation.lastMessage.body);
                                break;
                            case "media":
                                var encodedString = new URL(unreadConversation.lastMessage.attachment["url"]).pathname.split('/').pop();
                                var decodedString = decodeURIComponent(encodedString);
                                var fileName = decodedString.split('/').pop();
                                lastMessageText.text(fileName);
                                break;
                        }

                        lastUpdate.text("Last update: " + Chat.formatTime(unreadConversation.lastMessage.timestamp));

                        conversationPreview.parent().prepend(conversationPreview);
                    }
                }
            });
        });
    },

    handleSentMessages(inbox) {
        inbox.on("sendMessage", function (e) {
            if (e.message.type === 'UserMessage') {
                let kwayga_conversation_id = parseInt(e.conversation.custom.kwayga_conversation_id);
                if (kwayga_conversation_id) {
                    $.ajax({
                        url: `/conversations/${kwayga_conversation_id}`,
                        type: 'PATCH',
                        data: {
                            conversation: {
                                last_message_sent_at: new Date().toISOString(),
                                message_text: e.message.text
                            }
                        }
                    });
                }
            }
        });
    },

    handleMessageActions(inbox) {
        inbox.onCustomMessageAction('sampleRequest', this.processRequest.bind(this, 'sample', '/deal_sample_requests'));
        inbox.onCustomMessageAction('informationRequest', this.processRequest.bind(this, 'information', '/deal_information_requests'));
        inbox.onCustomMessageAction('pricingRequest', this.processRequest.bind(this, 'pricing', '/deal_pricing_requests'));
    },

    processRequest(requestType, baseUrl, event) {
        const requestId = this.getRequestId(requestType, event);
        const choiceActions = {
            accept: () => this.updateRequestStatus(baseUrl, requestId, 'accepted'),
            reject: () => this.updateRequestStatus(baseUrl, requestId, 'rejected'),
            'contact-kwayga': () => window.location.href = '/conversations/support',
            'tracking-info': () => requestType === 'sample' && this.addTrackingInfo(baseUrl, requestId),
            info: () => requestType === 'information' && this.addAdditionalInfo(baseUrl, requestId),
            pricing: () => requestType === 'pricing' && this.addAdditionalInfo(baseUrl, requestId)
        };
        if (choiceActions[event.params.choice]) {
            choiceActions[event.params.choice]();
        }
    },

    getRequestId(requestType, event) {
        const requestIds = {
            sample: event.params.sampleRequestId,
            information: event.params.infoRequestId,
            pricing: event.params.pricingRequestId
        };

        return parseInt(requestIds[requestType]);
    },

    updateRequestStatus(baseUrl, requestId, status) {
        $.ajax({
            url: `${baseUrl}/${requestId}`,
            type: 'PATCH',
            data: {status}
        });
    },

    addTrackingInfo(baseUrl, requestId) {
        $.ajax({
            url: `${baseUrl}/${requestId}/add_tracking_information`,
            type: 'GET'
        });
    },

    addAdditionalInfo(baseUrl, requestId) {
        $.ajax({
            url: `${baseUrl}/${requestId}/add_additional_information`,
            type: 'GET'
        });
    },

    formatTime(timestamp) {
        var time = new Date(timestamp);
        var today = new Date();
        var isToday = (time.toDateString() === today.toDateString());

        if (isToday) {
            return time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        } else {
            return time.toLocaleDateString([], {day: '2-digit', month: 'short', year: 'numeric'});
        }
    }
}
