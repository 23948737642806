window.ResendEmailConfirmation = {
  init() {
    const self = this;
    $("#resend-verify").click(function() {
      self.validateForm()
    });
  },

  emailProviderMsg() {
    return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
  },

  validateForm() {
    $("#verify-email").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          email: true,
          checkEmailDomain: true
        }
      }
    });

    $.validator.addMethod("checkEmailDomain", function(value) {
      let emailProvider = false
      domain = value.split("@")[1]
      $.ajax({ url: `users/registration/check_email_domain`,
        data: { 'user[email]': value },
        dataType: 'json',
        async: false,
        success: function(data) {
          emailProvider = data;
        }
      });
      return emailProvider;
    }, this.emailProviderMsg);
  },
}
