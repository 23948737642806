window.CompanyProducts = {
  init() {
    this.handleThumbnailImageOnClick();
  },

  handleThumbnailImageOnClick() {
    $(".thumbnail-image").on('click', (e) => {
      $(e.target).parent().siblings().find(".large-image").attr("src", e.target.dataset.largeImageUrl);
    } )
  }
};
