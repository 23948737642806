window.VideoCall = {
  init() {
    this.addClickEventHandler();
  },
  addClickEventHandler() {
    $(document).ready(function() {
      var btnStartVideoCall = $("#video_btn");
      btnStartVideoCall.click(function() {
        window.top.DailyVideo.startCall();
      });

      var btnProposeMeeting = $("#btn-propose-meeting");
      btnProposeMeeting.click(function() {
        window.top.Meetings.proposeMeeting();
      });

      var btnRevokeMeeting = $("#btn-revoke-meeting");
      btnRevokeMeeting.click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        window.top.Meetings.revokeMeeting(meetingID);
      });

      var btnAcceptMeeting = $("#btn-accept-meeting");
      btnAcceptMeeting.click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        window.top.Meetings.acceptMeeting(meetingID);
      });

      var btnRejectMeeting = $("#btn-reject-meeting");
      btnRejectMeeting.click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        window.top.Meetings.rejectMeeting(meetingID);
      });

      var btnCancelMeeting = $("#btn-cancel-meeting");
      btnCancelMeeting.click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        window.top.Meetings.cancelMeeting(meetingID);
      });

      var btnAddMeetingToCal = $("#btn-add-meeting-to-cal");
      btnAddMeetingToCal.click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        let start = $(e.target).data('start');
        let tz = $(e.target).data('tz');
        let title = $(e.target).data('title');
        let desc = $(e.target).data('desc');
        let location = $(e.target).data('location');
        window.top.Meetings.addMeetingToCalendar(
          start,
          tz,
          title,
          desc,
          location
        );
      });
    });
  }
}
