import {initMultiSelect2} from "./select2"

let isFilterShowed = false;
window.Connections = {
  init() {
    this.bindFilterFormBtn();
    this.bindClearFilterBtn();
    initMultiSelect2('.multiple-select-box', {
      width: '100%',
      placeholder: 'Select multiple'
    });
    this.handleRating();
    this.handleCompanyAgeRange();
    this.SeeMoreLessConnectionRequests();
  },

  bindFilterFormBtn() {
    $(".filter-form-btn").click(() => {
      isFilterShowed = !isFilterShowed
      if (isFilterShowed) {
        $(".connection-list-container").hide()
        $("#filter-form").show()
        $("#filter-title").hide()
      } else {
        $(".connection-list-container").show()
        $("#filter-form").hide()
        $("#filter-title").show()
      }
    })
  },

  bindClearFilterBtn() {
    $("#clear-btn").click(() => {
      $(".select2-selection__choice").remove()
      $('.selectbox option').prop('selected', false);
      $(':checkbox').prop('checked', false);
      $('#rating-list :checkbox').prop('checked', true).trigger('click');
      $('[name="filter[min_company_age]"]').val('');
      $('[name="filter[max_company_age]"]').val('');
    })
  },

  handleRating(){
    $('.rating-box').on('click', function(){
      console.log($(this).val())
      $('[name="filter[min_rating]"]').val(this.value)
      $('#min_ratings_' + this.value).prop("checked", true)
      for(var i = this.value; i <= 5; i++) {
        $('#min_ratings_' + i).prop("checked", true)
      }
      for(var i = 0; i < this.value; i++) {
        $('#min_ratings_' + i).prop("checked", false)
      }
    })
  },

  handleCompanyAgeRange() {
    $('#company-age-range-select').change(() => {
      let ageRange = $('#company-age-range-select').val().split('-')
      if (ageRange != ['']) {
        if (ageRange[0]) {
          $('[name="filter[min_company_age]"]').val(ageRange[0].trim());
        }

        if(ageRange[1]) {
          $('[name="filter[max_company_age]"]').val(ageRange[1].trim());
        } else {
          $('[name="filter[max_company_age]"]').val('');
        }
      } else {
        $('[name="filter[min_company_age]"]').val('');
        $('[name="filter[max_company_age]"]').val('');
      }
    })
  },

  SeeMoreLessConnectionRequests () {
    $(".request__list ").children(":gt(4)").hide();

      $('.show-more-request').on('click', function() {
        $('.request__list').children(":gt(4)").toggle();
        $(this).text() == 'Show more' ? $(this).text('Show less') : $(this).text('Show more');
      });
  }
}
