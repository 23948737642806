const formatCountryDropDown = function(state) {
  let $state = undefined;
  if (!state.id) {
    return state.text;
  }
  $state = $('<span><img src="' + state.element.dataset.flagPath + '" class="img-flag select2-flag-img" /> ' + state.text + '</span>');
  return $state;
};

const formatCountrySelected = function(state) {
  if (!state.id) {
    return state.text;
  }
  const $state = $('<span><img class="img-flag select2-flag-img" /> <span></span></span>');
  if (state.element.dataset.callingCode) {
    $state.find('span').text(state.element.dataset.callingCode);
  } else {
    $state.find('span').text(state.text);
  }

  $state.find('img').attr('src', state.element.dataset.flagPath);
  return $state;
};

export const initSelect2OnElement = function(element, options = {}) {
  return $(element).select2(options);
}

export const initCountrySelect2 = function() {
  $('.country-select2').select2({
    templateResult: formatCountryDropDown,
    templateSelection: formatCountrySelected,
    dropdownAutoWidth : true
  });
}

export const initCertificateSelect2 = function(selector = '.multi-select2-certificates', selectOptions = {}) {
  $(selector).select2({
    tags: true,
    selectOptions
  })
}

export const initMultiSelect2 = function(multiSelectName = '.multi-select2', selectOptions = {}) {
  $(multiSelectName).select2(selectOptions);
}

export const initTreeDropdown = function(selector = '.tree-dropdown', selectOptions = {}) {
  $(selector).each(function(){
    const data = $(this).data().options;
    $(this).select2({
      data: $(this).data().options,
      placeholder: $(this).data().placeholder
    });
  })
}

export const initDynamicElements = function() {
  setTimeout(function() {
    $(document).bind('DOMNodeInserted', function(e) {
      var elements = $(e.target).find(".multi-select2");
      if (elements.length > 0) {
        initSelect2OnElement(elements);
      }
    });
  }, 2000);
}

$(() => {
    initCountrySelect2();
    initMultiSelect2();
    initDynamicElements();
    initTreeDropdown();
    initCertificateSelect2();
  }
);
