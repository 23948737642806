export const CLEARBIT_URL = 'https://logo.clearbit.com/'
export const URL_REGEX = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
export const EMAIL_REGEX = /\S+@\S+\.\S+/

export const WEBSITE_URL_REGEX = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(?::\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i

export const WEBSITE_URL_PREFIX_REGEX = /^(http|https|ftp):\/\//

export const PHONE_NUMBER = /^[+#*\(\)\[\]]*([0-9][ ext+-pw#*\(\)\[\]]*){5,45}$/

export const FULL_EMAIL = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/

export const PHONE_EMAIL = new RegExp("(" + PHONE_NUMBER.source + ")|(" + FULL_EMAIL.source + ")")
