import {initCountrySelect2} from "./select2"
import {initTreeDropdown} from "./select2"

window.ServiceDetails = {
  init() {
    this.handleAddMore();
    this.validateForm();
  },

  handleAddMore() {
    $('#geography-wrapper').on('cocoon:after-insert', initCountrySelect2);
  },

  validateForm() {
    var self = this;
    $("#service-details-form").validate({
      rules: {
        "company_detail[address_line_1]": {
          required: true
        },
        "company_detail[address_zipcode]": {
          required: true
        },
        "company_detail[address_country_id]": {
          required: true
        }
      },
      errorPlacement: function (error, element) {
        if(element.hasClass('country-select2') && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        } else if(element.hasClass('multi-select2') && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        } else {
          error.insertAfter(element)
        }
      }
    });

    $.validator.addClassRules("company-target-geos", { companyTargetGeosDuplicates: true });
    $.validator.addClassRules("company-target-sectors", { companyTargetSectorsDuplicates: true });
    $.validator.addClassRules("company-languages", { companyPrefLanguagesDuplicates: true });

    $.validator.addMethod ("companyTargetGeosDuplicates", function (value, element) {
      var index = self.companyTargetGeoElementIndex(element);
      var previousValues = $(".company-target-geos").filter(
        (_,el) => self.companyTargetGeoElementIndex(el) < index
      ).map(
        (_,el) => el.value
      ).toArray();

      if(previousValues.length < 1) {
        return true;
      }

      var selectedValue = element.value;
      return previousValues.indexOf(selectedValue) < 0;
    }, "Same target geography can't be selected more than once");

    $.validator.addMethod ("companyTargetSectorsDuplicates", function (value, element) {
      var index = self.companyTargetSectorElementIndex(element);
      var previousValues = $(".company-target-sectors").filter(
        (_,el) => self.companyTargetSectorElementIndex(el) < index
      ).map(
        (_,el) => el.value
      ).toArray();

      if(previousValues.length < 1) {
        return true;
      }
      var selectedValue = element.value;
      return previousValues.indexOf(selectedValue) < 0;
    }, "Same target sector can't be selected more than once");

    $.validator.addMethod ("companyPrefLanguagesDuplicates", function (value, element) {
      var index = self.companyPrefLangsElementIndex(element);
      var previousValues = $(".company-languages").filter(
        (_,el) => self.companyPrefLangsElementIndex(el) < index
      ).map(
        (_,el) => el.value
      ).toArray();

      if(previousValues.length < 1) {
        return true;
      }

      var selectedValue = element.value;
      return previousValues.indexOf(selectedValue) < 0;
    }, "Same language can't be selected more than once");
  },

  getMultiElementIndex(element, id_prefix, id_suffix) {
    var elementID = element.id;
    var elementIndex = parseInt(
      elementID.replace(
        id_prefix, ''
      ).replace(
        id_suffix, ''
      )
    );
    return elementIndex;
  },

  companyTargetGeoElementIndex(element) {
    return this.getMultiElementIndex(
      element,
      'company_detail_company_detail_geographies_attributes_',
      '_geography_id'
    );
  },

  companyTargetSectorElementIndex(element) {
    return this.getMultiElementIndex(
      element,
      'company_detail_company_detail_sector_serveds_attributes_',
      '_category_id'
    );
  },

  companyPrefLangsElementIndex(element) {
    return this.getMultiElementIndex(
      element,
      'company_detail_company_detail_languages_attributes_',
      '_language_id'
    );
  }
};
