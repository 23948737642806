window.AddMember = {
  init() {
    this.validateAddMemberForm();
  },

  emailProviderMsg() {
    return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`;
  },

  validateAddMemberForm() {
    $('#add-member-form').validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          email: true,
          checkEmailDomain: true,
          remote: {
            url: `/admin/companies/check_email`,
            data: {
              check_exists: true
            }
          },
        },
        "user[first_name]": {
          required: true
        },
        "user[confirm_email]": {
          required: true,
          equalTo: '#email_1'
        },
        "user[last_name]": {
          required: true
        }
      },
      messages: {
        "user[email]": {
          remote: "Email already in use at Kwayga"
        }
      }
    });

    $.validator.addMethod("checkEmailDomain", function(value) {
      let emailProvider = false;
      const domain = value.split("@")[1];
      $.ajax({ 
        url: `/admin/companies/check_email_domain`,
        data: { 'user[email]': value },
        dataType: 'json',
        async: false,
        success: function(data) {
          emailProvider = data;
        }
      });
      return emailProvider;
    }, this.emailProviderMsg);
  }
};
