import {manualFileUploaded} from './preview_uploaded_file'
import {WEBSITE_URL_REGEX} from './constants'

const postObserver = new IntersectionObserver(
  function(entries) {
    for(let i=0; i<entries.length; i++) {
      if(entries[i].isIntersecting === true) {
        var element = $(entries[i]['target']);
        if (!element.hasClass('viewed')) {
          element.addClass('viewed');
          trackView(element);
        }
      }
    }
  }
);

const observePosts = () => {
  var posts = document.querySelectorAll(".post_detail[id]");
  for (let i = 0; i < posts.length; ++i) {
    var post = $(posts[i]);
    if (!post.hasClass('observed')) {
      post.addClass('observed');
      postObserver.observe(posts[i]);
    }
  }
};

const trackView = (element) => {
  var element_id = $(element)[0].id.replace('post-', '');
  $.ajax({
    url: `/posts/viewed`,
    type: 'POST',
    data: {
      uuid: element_id
    }
  });
};

window.Posts = {
  init() {
    this.loadMorePostsOnScroll();
    this.initPostNewModal();
    this.showEditModal();
    this.trackPostViews();
  },

  initPostNewModal() {
    this.selectAttachmentType();
    this.previewFile();
    this.setUrl();
    this.validateForm();
    this.showPostAttachment();
    this.clearAttachment();
    this.setDefaultFocus();
    this.loadDefaultModalOption();
    this.initConfirmationModalEvent();
  },

  loadDefaultModalOption() {
    $('#newPostModal').on('show.bs.modal', function(e) {
      var selectedOption = $(e.relatedTarget).data('option');
      $('.select_att_type[value="' + selectedOption + '"]').trigger('click');
    });
  },

  setDefaultFocus() {
    $('#newPostModal').on('shown.bs.modal', function() {
      $('.post_textarea').focus();
    });

    $('.post_main_img').bind('contextmenu',function() { return false; });

    $('#post_description').on('focus', function() {
      document.body.scrollTop = $(this).offset().top;
    });
  },

  trackPostViews() {
    setInterval(function() {
      observePosts();
    }, 100);
  },

  loadMorePostsOnScroll() {
    $(window).scroll(() => {
      if ($(window).height() + $(window).scrollTop() >= $(document).height() - 645) {
        let url = $('.posts-list-paging').find("li.page-item > a.page-link[rel='next']").attr('href');
        $('.posts-list-paging').remove()
        if (url) {
          $.getScript(url);
        }
      }
    })
  },

  selectAttachmentType() {
    $('.select_att_type').click((e) => {
      let attachmentType = $(e.target).attr('value');

      if (attachmentType == 'image') {
        this.setFileUploadContentType('image/*');
      } else if (attachmentType == 'video') {
        this.setFileUploadContentType('video/*');
      } else {
        this.setFileUploadContentType('*/*');
      }

      $('.preview-uploaded-file').hide();
      $('#url-input').hide();
      $('#post_post_attachment_attributes_attachment_type').val(attachmentType);

      if (attachmentType != 'link') {
        $('#post_post_attachment_attributes_attachment').trigger('click');
      } else {
        $('#url-input').show();
        $('#url-input span').focus();
        $('.clear-all-attachment-btn').show();
      }
      return false
    });
  },

  setFileUploadContentType(contentType) {
    $('#post_post_attachment_attributes_attachment').attr(
      'accept',
      contentType
    );
  },

  previewFile() {
    $('#post_post_attachment_attributes_attachment').change((e) => {
      let attType = $('#post_post_attachment_attributes_attachment_type').val()
      let reviewerEle = `#${attType}-file-reviewer`
      $('.preview-uploaded-file').hide()
      manualFileUploaded(e, reviewerEle, (attType != 'image' && attType != 'video'))
      $(reviewerEle).show()
      $('.clear-all-attachment-btn').show();
    });
  },

  setUrl() {
    $('#set-url').click(() => {
      let url = $('#url-input').find('span').text()
      if (this.isValidUrl(url)) {
        $('#url-input').hide()
        $('#document-file-reviewer').show().text(url)
        $('#post_post_attachment_attributes_url').val(url)
        $('.clear-all-attachment-btn').show()
      } else {
        alert(url + ' is not a valid URL')
      }
    })
  },

  validateForm() {
    $('#post-form').submit((e) => {
      let attType = $('#post_post_attachment_attributes_attachment_type').val();
      let exist_att_type = $('#exist_att').attr('att_type');

      if (attType != '') {
        $('#post_post_attachment_attributes__destroy').val(0)
      }
      if ((attType == '' && $('#hidden-post-field').val() == '') ||
          (attType == 'link' && $('#post_post_attachment_attributes_url').val() == '') ||
          (
            ['image', 'video', 'document'].indexOf(attType) >= 0 &&
            $('#post_post_attachment_attributes_attachment').val() == '' &&
            ['image', 'video', 'document'].indexOf(exist_att_type) < 0
          )
      ) {
        alert('Atleast one field must be filled');
        // e.preventDefault();
        return false;
      }
    })
  },

  isValidUrl(string) {
    let regex = WEBSITE_URL_REGEX;
    return regex.test(string);
  },

  showPostAttachment() {
    $('#exist_att').ready(() => {
      let attType = $('#exist_att').attr('att_type')
      let attSrc = $('#exist_att').attr('src')
      let attCont = (attType == 'image' || attType == 'video') ? `#${attType}-file-reviewer` : '#document-file-reviewer'
      if (attType == 'image' || attType == 'video') {
        $(attCont).attr('src', attSrc)
      } else {
        $(attCont).text(attSrc)
      }
      if (attType) {
        $(attCont).show()
        $('.clear-all-attachment-btn').show()
      }
    })
  },

  showEditModal() {
    if ($('#is_show_modal').val() == 'true') {
      $('#newPostModal').modal('show')
    }
  },

  clearAttachment() {
    $('.clear-all-attachment-btn').click(() => {
      $('.clear-all-attachment-btn').hide()
      $('.preview-uploaded-file').hide()
      $('#url-input').hide()
      $('#post_post_attachment_attributes_attachment_type').val('')
      $('#post_post_attachment_attributes_url').val('')
      $('#post_post_attachment_attributes_attachment').val('')
      $('#post_post_attachment_attributes__destroy').val('1')
    })
  },

  initConfirmationModalEvent() {
    $('#newPostModal').off('hidden.bs.modal',this.showConfirmationModal)
    $('#newPostModal').on('hidden.bs.modal',this.showConfirmationModal)
  },

  showConfirmationModal() {
    $('#confirmPostModal').modal('show')
  }
};
