window.AddBuyer = {
  init() {
    this.validateForm();
  },
  emailProviderMsg() {
    return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
  },

  validateForm() {
    $.validator.addMethod("checklower", function(value) {
      return /[a-z]/.test(value);
    });
    $.validator.addMethod("checkupper", function(value) {
      return /[A-Z]/.test(value);
    });
    $.validator.addMethod("checkdigit", function(value) {
      return /[0-9]/.test(value);
    });
    $.validator.addMethod("checkspecial", function(value) {
      return /[@$!%*#?&]/.test(value);
    });
    $.validator.addMethod("checkPresence", function(value) {
      return value && value.length > 0;
    });


    $("#add_buyer").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {

        "user[email]": {
          required: true,
          checkEmail: true,
          checkEmailDomain: true,
          emailVerified: true
        }, 
        "user[password]": {
          required: true,
          minlength: 8,
          checklower: true,
          checkupper: true,
          checkdigit: true,
          checkspecial: true
        },
        "user[confirm_password]": {
          required: true,
          equalTo: '#pass_log_id'
        },
        "user[first_name]": {
          required: true,
          minlength: 2,
          maxlength: 50
        },

        "user[last_name]": {
          required: true,
          minlength: 2,
          maxlength: 50
        }
      },
      messages: {
        "user[email]": {
          checkEmail: 'Email already taken',
        },
        "user[password]": {
          checkspecial: "Must contain at least 1 special character @$!%*#?&",
          checklower: "Must contain at least 1 lowercase character",
          checkupper: "Must contain at least 1 uppercase character",
          checkdigit: "Must contain at least 1 number"
        },
        "user[confirm_password]": {
          equalTo: 'Please enter the same password.'
        }
      },
      errorPlacement: (error, element) => {
        if(element.attr('name') === 'user[agree_terms]')
          error.insertAfter(element.next());
        else if(element.attr('name') === 'user[password]')
          error.insertAfter(element.next());
        else if(element.attr('name') === 'user[confirm_password]')
          error.insertAfter(element.next());
        else
          error.insertAfter(element);
      },
      onfocusout: function(element) {
        return $(element).valid();
      }
    });

    $.validator.addMethod("checkEmail", function(value) {
      var alreadTaken = false
      $.ajax({ url: `check_email`,
        data: { check_exists: true, 'user[email]': value},
        dataType: 'json',
        async: false,
        success: function(data) {
          alreadTaken = data
        }
      });
      return alreadTaken;
    });

    $.validator.addMethod("checkEmailDomain", function(value) {
      let emailProvider = false
      domain = value.split("@")[1]
      $.ajax({ url: `check_email_domain`,
        data: { 'user[email]': value },
        dataType: 'json',
        async: false,
        success: function(data) {
          emailProvider = data;
        }
      });
      return emailProvider;
    }, this.emailProviderMsg);

    $.validator.addMethod("emailVerified", function(value) {

      var alreadyconfirmed = false
      $.ajax({ url: `check_email_verified`,
        data: { 'user[email]': value},
        dataType: 'json',
        async: false,
        success: function(data) {
          alreadyconfirmed = data
        }
      });
      if (!alreadyconfirmed){  
        // window.location.replace(`/verify-email?email=${value}`)
      }else {
        return alreadyconfirmed;
      }
    }, this.emailVerifiedMsg);
  }
};

window.CompanyInfo = {
  init () {
    this.validateCompanyDetailForm();
  },

  validateCompanyDetailForm () {
    $("#compnay_info_form").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "company_detail[range_of_inception]": {
          required: true,
        },
        "company_detail[number_of_employees]": {
          required: true,
        },
        "company_detail[country_id]": {
          required: true,

        },
        "company_detail[yearly_turnover]": {
          required: true
        }
      },
      errorPlacement: (error, element) => {
        error.insertAfter(element);
      },
      onfocusout: function(element) {
        return $(element).valid();
      }
    });
  }
};
