window.CreateUser = {
    init(defaultTeamId = "") {
        this.addModalShownHandler(defaultTeamId);
    },

    addModalShownHandler(defaultTeamId = "",) {
        $('#create-new-user-modal').on('show.bs.modal', function () {
            $('#create-new-user-modal input[name="user[first_name]"]').val("");
            $('#create-new-user-modal input[name="user[last_name]"]').val("");
            $('#create-new-user-modal input[name="user[email]"]').val("");
            $('check_box').prop('checked', false);

            var userTeamIdsElement = $("#user_team_ids");
            if (userTeamIdsElement.attr('type') !== 'hidden') {
                userTeamIdsElement.select2().val(defaultTeamId).trigger('change');
            }
            $('#create-new-user-form').valid();
        });
    }
}
