window.SignUp = {
  init() {
    this.validateForm();
    // this.emailVerified();
    let domain = ''
  },
  emailVerifiedMsg() {
    return `Your account is already created  please sign in to continue.`
  },
  emailProviderMsg() {
    return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
  },

  validateForm() {
    $("#new_user").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          checkEmail: true,
          checkEmailDomain: true,
          emailVerified: true
        }, 
        "user[confirm_email]": {
          required: true,
          equalTo: '#email'
        }
      },
      messages: {
        "user[email]": {
          checkEmail: 'Email already taken'
        },
        "user[confirm_email]": {
          equalTo: 'Please enter the same email.'
        }
      },
      errorPlacement: (error, element) => {
        error.insertAfter(element.parent());
      },
      onfocusout: function(element) {
        return $(element).valid();
      }
    });

    $.validator.addMethod("checkEmail", function(value) {
      var alreadTaken = false
      $.ajax({ url: `registration/check_email`,
        data: { check_exists: true, 'user[email]': value},
        dataType: 'json',
        async: false,
        success: function(data) {
          alreadTaken = data
        }
      });
      return alreadTaken;
    });

    $.validator.addMethod("equalTo", function(value, element, param) {
      return this.optional(element) || value.toLowerCase() === $(param).val().toLowerCase();
    }, 'Please enter the same email.');

    $.validator.addMethod("checkEmailDomain", function(value) {
      let emailProvider = false
      domain = value.split("@")[1]
      $.ajax({ url: `registration/check_email_domain`,
        data: { 'user[email]': value },
        dataType: 'json',
        async: false,
        success: function(data) {
          emailProvider = data;
        }
      });
      return emailProvider;
    }, this.emailProviderMsg);

    $.validator.addMethod("emailVerified", function(value) {

      var alreadyconfirmed = false
      $.ajax({ url: `registration/check_email_verified`,
        data: { 'user[email]': value},
        dataType: 'json',
        async: false,
        success: function(data) {
          alreadyconfirmed = data
        }
      });
      if (!alreadyconfirmed){  
        // window.location.replace(`/verify-email?email=${value}`)
      }else {
        return alreadyconfirmed;
      }
    }, this.emailVerifiedMsg);
  }
};
