import {WEBSITE_URL_REGEX} from "../constants";

window.UpdateDealSampleRequest = {
    init() {
        this.validateAddTrackingDealSampleRequestForm();
    },

    validateAddTrackingDealSampleRequestForm() {
        $('#add-tracking-sample-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "tracking_url": {
                    required: true,
                    websiteUrl: true
                },
                "expected_delivery_date": {
                    required: true
                },
            },
            messages: {
                'tracking_url': 'Please provide a valid tracking url',
                'expected_delivery_date': 'Please provide the expected delivery date'
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
        
        $.validator.addMethod('websiteUrl', function (value) {
            return WEBSITE_URL_REGEX.test(value);
        }, 'Please enter a valid tracking url.');
    },
};
