const AUTH_TOKEN = $('meta[name="csrf-token"]').attr('content');

window.UpdatePricingRequest = {
    init() {
        this.validateAddPricingDealPricingRequestForm();
        this.initDropZone();
    },

    validateAddPricingDealPricingRequestForm() {
        $('#add-pricing-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "supplier_response": {
                    required: true
                },
                "deal_pricing_request_attachment_ids[]": {
                    required: true,
                }
            },
            messages: {
                'supplier_response': 'Please provide the additional information requested by the buyer',
                'deal_pricing_request_attachment_ids[]': 'Please upload required files and your latest catalogue'
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    initDropZone() {
        const submitButton = document.getElementById('add-pricing-request-modal-btn');
        const self = this
        $(".dropzone").not(".dz-clickable").each((index, element) => {
            $(element).dropzone({
                url: "/deal_pricing_requests/upload_deal_pricing_request_attachment",
                previewsContainer: $(element).siblings('.drop-zone-preview')[0],
                previewTemplate: document.querySelector('.drop-zone-custom').innerHTML,
                uploadMultiple: false,
                maxFiles: 10,
                maxFileSize: 10,
                acceptedFiles: ".jpg,.jpeg,.gif,.png,.svg,.tiff,.bmp,.webp,.txt,.csv,.pdf,.xls,.xlsx,.doc,.docx",
                init: function () {
                    this.on("error", function (file) {
                        this.removeFile(file);
                        $('#deal-pricing-request-attachments').after(
                            '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">' +
                            '<button type="button" class="close" ' +
                            'data-dismiss="alert" aria-hidden="true">' +
                            '&times;' +
                            '</button>' +
                            'An error has occurred uploading file' +
                            '</div>'
                        );
                        submitButton.disabled = false;
                    });
                },
                params: {
                    'authenticity_token': AUTH_TOKEN
                },
                success: (file, response) => {
                    if (response.err_msg == '' || response.err_msg == undefined) {
                        const dealPricingRequestAttachmentsElement = $("#deal-pricing-request-attachments");
                        $(file.previewElement).find('.remove-attachment').attr('data-attachment-id', response.id);
                        dealPricingRequestAttachmentsElement.append(`<input type='hidden' value='${response.id}' name='deal_pricing_request_attachment_ids[]'>`);
                        self.handleOnClickRemoveAttachment();
                    } else {
                        alert(response.err_msg);
                        file.previewElement.remove();
                    }
                    submitButton.disabled = false;
                },
                sending: () => {
                    submitButton.disabled = true;
                }
            });
        });

        $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function () {
            $("#rr-success-alert").slideUp(500);
        });
        setTimeout(function () {
            $("#rr-success-alert").remove();
        }, 6000);
    },

    handleRemoveAttachment(e) {
        const attachmentId = e.target.dataset.attachmentId
        $(`#deal-pricing-request-attachments :input[value="${attachmentId}"]`).remove()
        $(e.target).parents('.dz-preview').remove()
    },

    handleOnClickRemoveAttachment() {
        $(".remove-attachment").on('click', this.handleRemoveAttachment.bind(this))
    },
};
