const AUTH_TOKEN = $('meta[name="csrf-token"]').attr('content');

window.Catalog = {
    init() {
        this.initDropZone();
        this.handleAddCatalog();
        this.validateForm();
    },

    initDropZone() {
        const self = this
        const submitBtn = $('#add-catalog-btn')
        $(".dropzone").not(".dz-clickable").each((index, element) => {
            $(element).dropzone({
                url: "/catalogs/upload_catalog_attachment",
                previewsContainer: $(element).siblings('.drop-zone-preview')[0],
                previewTemplate: document.querySelector('.drop-zone-custom').innerHTML,
                uploadMultiple: false,
                maxFiles: 1,
                maxFileSize: 10,
                thumbnailWidth: 75,
                thumbnailHeight: 75,
                acceptedFiles: "application/pdf",
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeFile(file);
                        $('.dropzone').after(
                            '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">' +
                            '<button type="button" class="close" ' +
                            'data-dismiss="alert" aria-hidden="true">' +
                            '&times;' +
                            '</button>' +
                            'Only one file can be uploaded at a time' +
                            '</div>'
                        );
                    });
                    this.on("maxfilesreached", function (file) {
                        $('.dropzone').hide();
                    });
                    this.on("removedfile", function (file) {
                        const catalogAttachmentIdElement = $("input[name=\"catalog[catalog_attachment_id]");
                        catalogAttachmentIdElement.val(null);
                        catalogAttachmentIdElement.trigger('change');
                        file.previewElement.remove();
                        $('.dropzone').show();
                    });
                    this.on("error", function (file) {
                        this.removeFile(file);
                        $('.dropzone').after(
                            '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">' +
                            '<button type="button" class="close" ' +
                            'data-dismiss="alert" aria-hidden="true">' +
                            '&times;' +
                            '</button>' +
                            'This file format is not supported' +
                            '</div>'
                        );
                    });
                },
                params: {
                    'authenticity_token': AUTH_TOKEN
                },
                success: (file, response) => {
                    if (response.err_msg == '' || response.err_msg == undefined) {
                        const catalogAttachmentIdElement = $("input[name=\"catalog[catalog_attachment_id]");
                        catalogAttachmentIdElement.val(`${response.id}`);
                        catalogAttachmentIdElement.trigger('change');
                    } else {
                        alert(response.err_msg);
                    }
                },
                sending: () => {
                    submitBtn.attr('disabled', 'disabled')
                }
            });
        });

        $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function () {
            $("#rr-success-alert").slideUp(500);
        });
        setTimeout(function () {
            $("#rr-success-alert").remove();
        }, 6000);
    },

    handleInsertCocoonEle() {
        this.initDropZone();
    },

    handleAddCatalog() {
        $('#catalogs-wrapper').on('cocoon:after-insert', this.handleInsertCocoonEle.bind(this));
    },

    validateForm() {
        const addCatalogButton = $('#add-catalog-btn');
        const productsServicesForm = $('#products-services-form');

        productsServicesForm.on('input change keyup', function () {
            const teamId = $('#catalog_team_id').val();
            const catalogName = $('#catalog-name').val();
            const attachmentId = $("input[name=\"catalog[catalog_attachment_id]").val();

            if (teamId && catalogName && attachmentId) {
                addCatalogButton.attr('disabled', false);
            } else {
                addCatalogButton.attr('disabled', true);
            }
        });
    },
};
