import consumer from "./consumer"

var conversationId = decodeURI(window.location.search).replace('?', '')
                     .split('&').map(param => param.split('=')).reduce((values, [ key, value ]) => { values[ key ] = value 
                      return values
                      }, {})["conversation_id"]

if (document.querySelector('meta[name=action-cable-url]')) {
  consumer.subscriptions.create({channel: "ConversationChannel", id: conversationId}, {
    connected() {
      console.log('connected!')
    },

    disconnected() {},

    received(data) {
      window.top.Meetings.reloadHtmlPanel();
    }
  });
}
