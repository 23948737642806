window.ResetPassword = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#new_user").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          email: true,
          remote: `/users/registration/check_email`
        }
      },
      messages: {
        "user[email]": {
          remote: "Email not found. Please correct email address or contact us for assistance"
        }
      }
    });
  }
};
