window.DeviceWarningPage = {
  init() {
    this.hideWarningDeviceMessage();
  },

  hideWarningDeviceMessage() {
    $('#btn-hide-warning-device').click(() => {
      $('#device-warning').remove();
      $.post('/hide_warning_device')
    })
  }
};
