window.EditTeam = {
    init() {
        this.validateTeamForm();
        this.handleDescriptionKeyup();
    },

    descriptionCounter() {
        var characterCount = $(this).val().length,
            current = $('#description-counter');
        current.text(characterCount);
    },

    handleDescriptionKeyup() {
        $('#team-details-form textarea[name="team[description]"]').on('keyup', this.descriptionCounter);
        $('#team-details-form textarea[name="team[description]"]').trigger('keyup');
    },

    validateTeamForm() {
        $('#team-details-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "team[name]": {
                    required: true,
                    minlength: 2,
                    maxlength: 50
                },
                "team[description]": {
                    required: true,
                    minlength: 10,
                    maxlength: $('#team-details-form textarea[name="team[description]"]').maxlength
                }
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    }
};
