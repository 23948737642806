import {initSelect2OnElement} from './select2'

window.Matches = {
  init() {
    $('#search-matches-form a.filter').click(function(){
      $('#search-matches-form').submit();
    });

    initSelect2OnElement('.sector-select', {placeholder: 'Sector'});
    initSelect2OnElement('.location-select', {placeholder: 'Location'});
  }
}
