window.ContactUs = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#contact-us-form").validate({
      rules: {
        "email": {
          required: true,
          email: true
        },
        "name": {
          required: true
        },
        "subject": {
          required: true
        },
        "message": {
          required: true
        },
      },
      errorPlacement: function (error, element) {
        error.insertAfter(element);
      }
    });
  }
};
