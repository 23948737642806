window.CreateTeam = {
    init() {
        this.validateTeamForm();
        this.handleDescriptionKeyup();
    },

    descriptionCounter() {
        var characterCount = $(this).val().length,
            current = $('#description-counter');

        current.text(characterCount);
    },

    handleDescriptionKeyup() {
        $('#create-new-team-modal textarea[name="team[description]"]').on('keyup', this.descriptionCounter);
        $('#create-new-team-modal textarea[name="team[description]"]').trigger('keyup');
    },

    validateTeamForm() {
        $('#create-new-team-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "team[name]": {
                    required: true,
                    minlength: 2,
                    maxlength: 50
                },
                "team[description]": {
                    required: true,
                    minlength: 10,
                    maxlength: $('#create-new-team-modal textarea[name="team[description]"]').maxlength
                },
                "team[tag_names]": {
                    required: true
                },
                "team[industry_ids][]": {
                    required: true
                },
            },
            errorPlacement: (error, element) => {
                if (element.hasClass('multi-select2') && element.next('.select2-container').length) {
                    error.insertAfter(element.next('.select2-container'));
                } else if (element.attr('name') === 'team[description]') {
                    error.insertAfter(element);
                } else {
                    error.insertAfter(element);
                }
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    }
};
