window.NewPassword = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $.validator.addMethod("checklower", function(value) {
      return /[a-z]/.test(value);
    });
    $.validator.addMethod("checkupper", function(value) {
      return /[A-Z]/.test(value);
    });
    $.validator.addMethod("checkdigit", function(value) {
      return /[0-9]/.test(value);
    });
    $.validator.addMethod("checkspecial", function(value) {
      return /[@$!%*#?&]/.test(value);
    });

    $("#edit_user").validate({
      rules: {
        "user[password]": {
          required: true,
          minlength: 8,
          checklower: true,
          checkupper: true,
          checkdigit: true,
          checkspecial: true
        },
        "user[password_confirmation]": {
          required: true,
          equalTo: "#user_password"
        }
      },
      messages: {
        "user[password]": {
          checkspecial: "Must contain at least 1 special character @$!%*#?&",
          checklower: "Must contain at least 1 lowercase character",
          checkupper: "Must contain at least 1 uppercase character",
          checkdigit: "Must contain at least 1 number"
        }
      }
    });
  }
};
