window.EditMember = {
    init(formName = '#edit-member-form') {
        this.validateEditMemberForm(formName);
        let domain = '';
    },

    emailProviderMsg() {
        return `We do not allow email addresses from ${domain}. Please use company emails.`
    },

    alreadyTakenMsg() {
        return 'Email already in use at Kwayga.'
    },

    validateEditMemberForm(formName) {
        $(formName).validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "user[email]": {
                    required: true,
                    email: true,
                    checkEmailDomain: true,
                    checkEmail: true
                },
                "user[first_name]": {
                    required: true,
                    minlength: 2,
                    maxlength: 50
                },
                "user[last_name]": {
                    required: true,
                    minlength: 2,
                    maxlength: 50
                },
                "user[role_name]": {
                    required: true
                },
            },
            onfocusout: function (element) {
                return $(element).valid();
            },
            submitHandler: function (form) {
                const submitButton = $("#create-new-user-modal-btn");
                submitButton.attr("disabled", true);
                form.submit();
            }
        });

        $.validator.addMethod("checkEmailDomain", function (value) {
            let emailProvider = false
            domain = value.split("@")[1]
            $.ajax({
                url: `users/registration/check_email_domain`,
                data: {'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    emailProvider = data;
                }
            });
            return emailProvider;
        }, this.emailProviderMsg);

        $.validator.addMethod("checkEmail", function (value) {
            var alreadyTaken = false
            $.ajax({
                url: `users/registration/check_email`,
                data: {check_exists: true, 'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    alreadyTaken = data
                }
            });
            return alreadyTaken;
        }, this.alreadyTakenMsg);
    }
};
