import {initSelect2OnElement} from './select2'

window.LiveSearch = {
  init() {
    this.initSelect2();
    this.loadMoreSupsOnScroll();
    this.initSelectAction();
    this.loadCompanyDetailOnSelect();
  },

  initSelect2() {
    this.locationSelector = initSelect2OnElement('#location')
    this.productTypeSelector = initSelect2OnElement('#product_type')
  },

  loadMoreSupsOnScroll() {
    $('.live_row').scroll(() => {
      if ($('.live_row').scrollTop() + $('.live_row').height() > $('.live_search_row').height()) {
        let url = $('.sups-list-paging').find("li.page-item > a.page-link[rel='next']").attr('href');
        $('.sups-list-paging').remove()
        if (url) {
          $.getScript(url)
        }
      }
    })
  },

  initSelectAction() {
    this.sectorSelector.on("change", function() {
      if (this.value != '') {
        $('#filter-submit').click()
      }
    })

    this.locationSelector.change(() => {
      if (this.value != '') {
        $('#filter-submit').click()
      }
    })
    this.productTypeSelector.change(() => {
      if (this.value != '') {
        $('#filter-submit').click()
      }
    })
  },

  redirectAction(additional) {
    window.location.replace(window.location.href + additional)
  },

  loadCompanyDetailOnSelect() {
    var self = this;
    $('.search_result').on('click', '.search_result_col', function(e) {
      var element = $(e.currentTarget);
      if (!element.hasClass('active')) {
        $('.search_result_col.active').removeClass('active');
        element.addClass('active');
        let companyID = element.attr('id').replace('search_result_company-', '');
        self.fetchAndRefreshCompanyDetail(companyID);
      }
    });
  },

  fetchAndRefreshCompanyDetail(companyID) {
    $.ajax({
      url: `/search/company_detail`,
      type: 'POST',
      data: {
        id: companyID
      }
    });
  }
}
