window.AccountSetting = {
  init() {
    this.validateGeneralForm();
    this.validateAddMemberForm();
    this.validateDeleteCompanyForm();
    this.handleReasonDeleteBtnOnClick();
  },

  emailProviderMsg() {
    return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
  },

  validateGeneralForm() {
    $('#edit-user-form').validate({
      rules: {
        "user[email]": {
          required: true,
          email: true,
        },
        "user[first_name]": {
          required: true
        },
        "user[last_name]": {
          required: true
        },
        "user[current_password]": {
          required: () => {
            return !!$("#user_password").val().length;
          }
        },
        "user[password]": {
          minlength: 7
        },
        "user[password_confirmation]": {
          equalTo: "#user_password"
        }
      },
      messages: {
        "user[current_password]": {
          required: 'Current password is required'
        },
        "user[password_confirmation]": {
          equalTo: "Confirm password doesn't match"
        }
      }
    });
  },

  validateAddMemberForm() {
    $('#add-member-form').validate({
      onsubmit: true,
      onkeyup: false,
      onclick: false,
      onfocusout: false,
      rules: {
        "user[email]": {
          required: true,
          email: true,
          checkEmailDomain: true,
          remote: {
            url: `users/registration/check_email`,
            data: {
              check_exists: true
            }
          },
        },
        "user[first_name]": {
          required: true
        },
        "user[confirm_email]": {
          required: true,
          equalTo: '#email_id_1'
        },
        "user[last_name]": {
          required: true
        }
      },
      messages: {
        "user[email]": {
          remote: "Email already in use at Kwayga"
        }
      }
    });

    $.validator.addMethod("checkEmailDomain", function(value) {
      let emailProvider = false
      domain = value.split("@")[1]
      $.ajax({ url: `users/registration/check_email_domain`,
        data: { 'user[email]': value },
        dataType: 'json',
        async: false,
        success: function(data) {
          emailProvider = data;
        }
      });
      return emailProvider;
    }, this.emailProviderMsg);
  },

  validateDeleteCompanyForm() {
    $('#delete-company-form').validate({
      rules: {
        'company[deleted_reason]': {
          required: true,
        }
      },
      errorPlacement: (error, element) => {
        if (element.attr('type') == 'radio') {
            error.insertBefore(element.parent());
        }
      }
    });
  },

  handleReasonDeleteBtnOnClick() {
    $('#reason-delete-btn').on('click', (e) => {
      e.preventDefault()
      if ( $('#delete-company-form').valid()) {
        $('#reason_delete_modal').modal('toggle')
        $('#delete_confirm_modal').modal('toggle')
      }
    })
  }
};


$(document).on('click', '.toggle-user-new-password', function() {
$(this).toggleClass("fa-eye-slash fa-eye");
var input = $(".user_new_password");
if (input.attr("type") === "password") {
  input.attr("type", "text");}
else {
  input.attr("type", "password");}
});
$(document).on('click', '.toggle-user-new-cnfrm-password', function() {
$(this).toggleClass("fa-eye-slash fa-eye");
var input = $(".user_new_cnfrm_password");
if (input.attr("type") === "password") {
  input.attr("type", "text");}
else {
  input.attr("type", "password");}
});

$(document).on('click', '.toggle-user-current-password1', function() {
$(this).toggleClass("fa-eye-slash fa-eye");
var input = $(".user_current_password");
if (input.attr("type") === "password") {
  input.attr("type", "text");}
else {
  input.attr("type", "password");}
});

