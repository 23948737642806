import { WEBSITE_URL_REGEX } from './constants'
import { PHONE_EMAIL } from './constants'
import {manualFileUploaded} from './preview_uploaded_file'

window.CompanyDetail = {
  init() {
    this.validateForm();
    this.hanldeManualLogoOnChange();
    this.submitForm();
  },

  hanldeManualLogoOnChange() {
    $('#logo-uploader').change((e) => manualFileUploaded(e, '#logo-reviewer'));
  },
  hanldeTestingCompany(email){
    let domain = (email.split("@")[1]).split('.')[0]
    if ( domain.toLowerCase() == 'kwayga'){
      $('#testing-company').removeClass("hidden")
    }
  },

  validateForm() {
    $("#company_webiste_url").validate({
      onsubmit: true,
      onkeyup: false,
      onclick: true,
      rules: {
        "company[website_url]": {
          required: true
        },
        "company[name]": {
          required: true
        },
        "hidden-field-name": {
          required: true
        }
      },
      errorPlacement: (error, element) => {
        error.insertAfter(element);
      },
      onfocusout: function(element) {
        $(element).val($.trim($(element).val()));
        return $(element).valid();
      }
    });

    $.validator.addClassRules("company-website-url", { websiteUrl: true });
    $.validator.addClassRules("hidden-field-c", { phoneUrl: true });

    $.validator.addMethod('websiteUrl', function (value) {
      return WEBSITE_URL_REGEX.test(value);
    }, 'Please enter a valid website');

    $.validator.addMethod('phoneUrl', function (value) {
      return !PHONE_EMAIL.test(value);
    }, 'Sorry, our system has identified contact details in your description, which are not allowed here, please edit your description to proceed - thank you');
  },

  submitForm() {
    $("#company_webiste_url").submit(function () {
      if ($("#company_webiste_url").valid()){
        $(this).find(":submit").attr('disabled', 'disabled');
      }
    });
  }
};

window.CompanyCategory = {
  init () {
    this.HandleCompanyCategory();
  },

  HandleCompanyCategory () {
    $('.buyer').click(function() {
      $('#supplier').prop('checked', false)
      $('#buyer').prop('checked', true)
      $(this).addClass('form-check-modern-selected')
      $('.supplier').removeClass('form-check-modern-selected')
      $("#company_category_btn").prop("disabled", false);
      $("#company_category_btn").removeClass("disabled");
   });

    $('.supplier').click(function () {
      $('#supplier').prop('checked', true)
      $('#buyer').prop('checked', false)
      $(this).addClass('form-check-modern-selected')
      $('.buyer').removeClass('form-check-modern-selected')
      $("#company_category_btn").prop("disabled", false);
      $("#company_category_btn").removeClass("disabled");
    });
  },
}
