const { ajax } = require("jquery");

window.Admin = {
  init() {
    this.handleShowMetricsOnChange();
    this.handleDealFeedMatchPreference();
  },

  handleShowMetricsOnChange() {
    $(document).ready(function() {
      $("#showMetrics").change(function() {
        var isEnabled = false;
        if ($(this).is(':checked')) {
          isEnabled = true
        }

        $.ajax({
          url: `/admin/update_show_metrics`,
          type: 'POST',
          data: {
            showMetrics: isEnabled
          }
        });
      });
    });
  },

  handleDealFeedMatchPreference() {
    $(document).ready(function() {
      $("#dealFeedMatchByPreference").change(function() {
        var isEnabled = false;
        if ($(this).is(':checked')) {
          isEnabled = true
        }

        $.ajax({
          url: `/admin/update_supplier_deal_feed_match_buyer_preference`,
          type: 'POST',
          data: {
            dealFeedMatchByPreference: isEnabled
          }
        });
      });
    });
  }
};
