const AUTH_TOKEN = $('meta[name="csrf-token"]').attr('content');

window.CreateDealInformationRequest = {
    init() {
        this.initDropZone();
    },

    initDropZone() {
        const self = this
        $(".dropzone.info-request").not(".dz-clickable").each((index, element) => {
            $(element).dropzone({
                url: "/deal_information_requests/upload_deal_information_request_attachment",
                previewsContainer: document.querySelector('.drop-zone-preview.info-request'),
                previewTemplate: document.querySelector('.drop-zone-custom.info-request').innerHTML,
                uploadMultiple: false,
                maxFiles: 10,
                maxFileSize: 10,
                acceptedFiles: ".jpg,.jpeg,.gif,.png,.svg,.tiff,.bmp,.webp,.txt,.csv,.pdf,.xls,.xlsx,.doc,.docx",
                init: function () {
                    this.on("error", function (file) {
                        this.removeFile(file);
                        $('#deal-information-request-attachments').after(
                            '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">' +
                            '<button type="button" class="close" ' +
                            'data-dismiss="alert" aria-hidden="true">' +
                            '&times;' +
                            '</button>' +
                            'An error has occurred uploading file' +
                            '</div>'
                        );
                    });
                },
                params: {
                    'authenticity_token': AUTH_TOKEN
                },
                success: (file, response) => {
                    if (response.err_msg == '' || response.err_msg == undefined) {
                        const dealInformationRequestAttachmentsElement = $("#deal-information-request-attachments");
                        $(file.previewElement).find('.remove-attachment').attr('data-attachment-id', response.id);
                        dealInformationRequestAttachmentsElement.append(`<input type='hidden' value='${response.id}' name='deal_information_request[deal_information_request_attachment_ids][]'>`);
                        self.handleOnClickRemoveAttachment();
                    } else {
                        alert(response.err_msg);
                        file.previewElement.remove();
                    }
                },
                sending: () => {
                }
            });
        });

        $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function () {
            $("#rr-success-alert").slideUp(500);
        });
        setTimeout(function () {
            $("#rr-success-alert").remove();
        }, 6000);
    },

    handleRemoveAttachment(e) {
        const attachmentId = e.target.dataset.attachmentId;
        $(`#deal-information-request-attachments :input[value="${attachmentId}"]`).remove();
        $(e.target).parents('.dz-preview.info-request').remove();
    },

    handleOnClickRemoveAttachment() {
        $(".remove-attachment").on('click', this.handleRemoveAttachment.bind(this));
    },
};
