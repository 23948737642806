window.AddTeams = {
  init(formName = '#add-user-to-teams-form') {
    this.validateAddTeamsForm(formName);
  },

  validateAddTeamsForm(formName) {
     $(formName).validate({
      rules: {
        'user_ids[]': {
          required: true
        },
        'team_ids[]': {
          required: true
        }
      },
      messages: {
        'user_ids[]': {
          required: "Please select at least one user"
        },
        'team_ids[]': {
          required: "Please select at least one team"
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element.parent().parent());
      }
    });
  }
};
