import { initSelect2OnElement, initMultiSelect2 } from './select2'

window.ConnectionRecommendation = {
  init() {
    this.initSelect2();
  },
  initSelect2() {
    console.log('initSelect2 called');
    const selectTags = document.querySelectorAll('#new_id');
    selectTags.forEach((selectTag) => {
      initSelect2OnElement(selectTag, {
        width: '100%',
        placeholder: 'Select multiple'
      });
    });
  },
}


