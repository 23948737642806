import {initSelect2OnElement, initMultiSelect2} from '../select2'

window.Matching = {
  init() {
    this.initSelect2();
    this.setBuyerSelectAction();
    this.setOnSubmitForm();
    this.toggleCompanySelected();
  },

  initSelect2() {
    this.buyerSelector = initSelect2OnElement('#buyer-selector');
    initMultiSelect2('#sups-selector', {
      width: '100%',
      placeholder: 'Select multiple'
    })
  },

  setBuyerSelectAction() {
    this.buyerSelector.change(() => {
      if (this.buyerSelector.val() != '-1') {
        window.location.replace(`matching?buyer_id=${this.buyerSelector.val()}`)
      }
    })
  },

  toggleSupsList() {
    if (this.buyerSelector.val() == '-1') {
      $('.sups-container').hide()
    } else {
      $('.sups-container').show()
    }
  },

  toggleCompanySelected() {
    if (this.buyerSelector.val() != '-1') {
      let buyerName = $('#buyer-selector :selected').text()
      let description = `Please select the companies you wish to suggest for <b>${buyerName}</b>.`
      $('#modal-text').html(description)
      $('#container-title').text(`Suggested Matches for ${buyerName}`)
    }
  },

  setOnSubmitForm() {
    $('#matcher_form').submit((e) => {
      let selectedBuyer = $('#buyer-selector').val()
      if ($('#sups-selector').val().length > 0) {
        $('#for_company_id').val(selectedBuyer)
      } else {
        $('#add-recommend-companies').find('.select2-selection').css('border-color', 'red')
        return false;
      }
    })
  }
}
