(function (g, r, s, f) {
  g.grsfSettings = { campaignId: "tyjzas", version: "2.0.0" };
  s = r.getElementsByTagName("head")[0];
  f = r.createElement("script");
  f.async = 1;
  f.src = "https://app.growsurf.com/growsurf.js" + "?v=" + g.grsfSettings.version;
  f.setAttribute("grsf-campaign", g.grsfSettings.campaignId);
  !g.grsfInit ? s.appendChild(f) : "";
})(window, document);

window.GrowSurf = {
  init(participantEmail){
    if(!window.growsurf) {
      // Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', () => {
        participant = growsurf.getParticipantByEmail(participantEmail);
        if (participant){
          growsurf.triggerReferral(participantEmail);
        }
      });
    }
    else{
      participant = growsurf.getParticipantByEmail(participantEmail);
      if (participant){
        growsurf.triggerReferral(participantEmail);
      }
    }
  }
}
