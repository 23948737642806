import {CLEARBIT_URL, WEBSITE_URL_REGEX, WEBSITE_URL_PREFIX_REGEX} from './constants'
import {manualFileUploaded} from './preview_uploaded_file'

window.ProfileDetails = {
  init() {
    this.hanldeManualLogoOnChange();
    this.handleWebsiteUrlFocusOut();
    this.handleDescriptionKeyup();
    this.validateForm();
  },

  autoFillLogoImage(e) {
    if (!$('#logo-reviewer').data('manualLogo')) {
      const logoUrl = e.target.value.replace(WEBSITE_URL_PREFIX_REGEX, "");
      const hostName = logoUrl.split('/')[0];
      const clearbitLogoUrl = CLEARBIT_URL + hostName;
      const logoElement = $('#logo-reviewer')
      $.ajax({
        url: clearbitLogoUrl,
        type: 'GET',
        dataType: 'html',
        timeout: 1000
      })
      .done(result => logoElement.attr('src', clearbitLogoUrl))
      .fail(() =>
        {
          const noLogoAsset = logoElement[0].dataset.noLogoAsset
          logoElement.attr('src', noLogoAsset)
        }
      )
    }
  },

  descriptionCounter() {
    var characterCount = $(this).val().length,
    current = $('#description-counter');

    current.text(characterCount);
  },

  hanldeManualLogoOnChange() {
    $('#logo-uploader').change((e) => manualFileUploaded(e, '#logo-reviewer'));
  },

  handleWebsiteUrlFocusOut() {
    $('#company_company_detail_attributes_website_url').on('focusout', this.autoFillLogoImage);
  },

  handleDescriptionKeyup() {
    $('#company_company_detail_attributes_description').on('keyup', this.descriptionCounter);
    $('#company_company_detail_attributes_description').trigger('keyup');
  },

  validateForm() {
    var self = this;
    $("#company-profile-detail-form").validate({
      rules: {
        "company[company_detail_attributes][description]": {
          required: true,
          maxlength: 500
        },
        "company[company_detail_attributes][range_of_inception]": {
          required: true
        },
        "company[company_detail_attributes][number_of_employees]": {
          required: true
        },
        "company[company_detail_attributes][country_id]": {
          required: true
        },
        "company[company_detail_attributes][yearly_turnover]": {
          required: true
        },
        "company[company_detail_attributes][currency_id]": {
          required: true
        }
      },
      errorPlacement: function (error, element) {
        if(element.hasClass('country-select2') && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        } else if(element.hasClass('multi-select2') && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        } else {
          error.insertAfter(element)
        }
      }
    });

    $.validator.addClassRules("company-industries", { companyIndustriesRequired: true });
    $.validator.addClassRules("company-industries", { companyIndustriesDuplicates: true });

    $.validator.addClassRules("company-website-url", { websiteUrl: true });

    $.validator.addMethod("companyIndustriesRequired", function (value, element) {
      return ($(".company-industries").filter((_,el) => el.value).length > 0 )
    }, "Select at least one industry");

    $.validator.addMethod("companyIndustriesDuplicates", function (value, element) {
      var index = self.companyIndustryElementIndex(element);
      var previousValues = $(".company-industries").filter(
        (_,el) => self.companyIndustryElementIndex(el) < index
      ).map(
        (_,el) => el.value
      ).toArray();
      if(previousValues.length < 1) {
        return true;
      }

      var selectedValue = element.value;
      return previousValues.indexOf(selectedValue) < 0;
    }, "Same industry can't be selected more than once");

    $.validator.addMethod('websiteUrl', function (value) {
      return WEBSITE_URL_REGEX.test(value);
    }, 'Please enter a valid website.');
  },

  companyIndustryElementIndex(element) {
    var elementID = element.id;
    var elementIndex = parseInt(
      elementID.replace(
        'company_company_industries_attributes_', ''
      ).replace(
        '_category_id', ''
      )
    );
    return elementIndex;
  }
};
