import '@popperjs/core';

const initCompanyPrivatePopover = () => {
  $('.company-private-info').popover({
    title : "This is not shown publicly",
    content: "While optional during setup, this may be required later to verify your company profile.",
    trigger: "focus",
    class: "company-private-info",
    placement: wheretoplace,
    template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  })
};

const wheretoplace = () => {
  var width = window.innerWidth;
  if (width < 992) return 'bottom';
  return 'right';
}

$(() => {
  initCompanyPrivatePopover();
  }
);
