import {EMAIL_REGEX} from './constants'

window.InviteForm = {
  init(current_company_name) {
    this.validateEmailInput();
  },
  initReviews(current_company_name, first_name, last_name) {
    this.validateEmailInput();
  },
  validateEmailInput() {
    $('#sent_invite_btn').click((e) => {
      let arrEmail = $('#invitation_invited_email').val().split(',')
      let formatedEmails = arrEmail.map(email => email.trim())
      let isFormat = true
      formatedEmails.forEach((em) => {
        if (!this.validateEmail(em)){
          isFormat = false;
          return;
        }
      })
      if (isFormat) {
        $('#invitation_invited_email').val(formatedEmails.join(','))
      } else {
        $('#invitation_invited_email').css('border-color', 'red');
        $('#invitation_invited_email').focus();
        e.preventDefault()
      }
    })
  },

  validateEmail(email) {
    var re = EMAIL_REGEX;
    return re.test(email);
  }
};
