import Talk from 'talkjs'
import getUserLocale from 'get-user-locale';

window.TalkMessaging = {
  init() {
    Talk.ready.then(
      function() {
        talk_me['locale'] = getUserLocale();
        var me = new Talk.User(talk_me);
        window.talkSession = new Talk.Session({
          appId: talk_app_id,
          me: me,
          signature: talkjs_signature
        });
        DailyVideo.createFrame();
      }
    );
    this.connectionsSearch();
    this.connectedCompaniesSearch();
    this.SearchUser();
    this.checkMessageExists();
    this.directMessagingUsers();
  },


  onConnectionListUpdated() {
    $(document).ready(function() {
    // Function to handle the mutations observed by MutationObserver
      function handleMutations(mutations) {
        var count = parseInt($('#chat-loader').data('total-connections'))
        console.log("Hello")
        console.log($('.user-chat-connection-list').children().length)
        console.log(count)
        console.log("Hello")
        if (count && ($('.user-chat-connection-list').children().length >= count)){
          $('.spinner-border').addClass('d-none');
          if($('.show-more').hasClass('d-none')){
            $('.show-more').removeClass('d-none');
            $('.show-more').click();
          }
          TalkMessaging.loadChat();
        }
      }

      var observer = new MutationObserver(handleMutations);
      var observerConfig = {
        childList: true,
        subtree: true
      };

      observer.observe(document.getElementsByClassName('user-chat-connection-list')[0], observerConfig);

    });
  },
  showMore() {
    window.onload = function() {
      var showMoreButton = $('.show-more');
      showMoreButton.text('Show more');

      showMoreButton.on('click', function() {
        var count = parseInt($('#chat-loader').data('total-connections'))
        if(count && $('.user-chat-connection-list').children().length >= count){

          $('.conversation-user__list:gt(2)').toggle();
          $('.conversation-user__list-drop-down:gt(2)').toggle();

          $(this).text($(this).text() === 'Show more' ? 'Show less' : 'Show more');
        }
        else if (count && $('.user-chat-connection-list').children().length != count){
          $('.show-more').addClass('d-none');
          $('.spinner-border').removeClass('d-none');
        }
        else{
          $('.conversation-user__list:gt(2)').toggle();
          $('.conversation-user__list-drop-down:gt(2)').toggle();

          $(this).text($(this).text() === 'Show more' ? 'Show less' : 'Show more');
        }
      });

      setTimeout(function() {
        $('#talkjs-messages').removeClass('hidden');
      }, 5000);
    };

    $(document).ready(function() {
      $('.show-more-groups').on('click', function() {
        $('.conversation-group__list:gt(0)').toggle();
        $(this).text() === 'Show more' ? $(this).text('Show less') : $(this).text('Show more');
      });
      setTimeout(function() {
        $('#talkjs-messages').removeClass('hidden')
      }, 5000);

      $('.show-more-company-chats').on('click', function() {
        var c_chat;
        $('.conversation-company__list:gt(2)').toggle();
        if($(this).text() === 'Show more') {
          $(this).text('Show less');
          c_chat = true;
        } else {
          c_chat = false;
          $(this).text('Show more');
        }

        $.ajax({
          url: 'see_more_less',
          method: 'post',
          data: {c_chat},
          success: function() {}
        })
      });
      setTimeout(function() {
        $('#talkjs-messages').removeClass('hidden')
      }, 5000);
    });

    $(document).on('click', '.direct-message-arrow-connections', function() {
      $(this).toggleClass("down");
    });

    $(document).on('click', '.toggle-arrow', function() {
      var target = $(this).data("target");
      $(`#user-msgs-span-${target.split('-').slice(-1)[0]}`).toggleClass("down");
    });

    $(".message-text").click(function() {
      $(".direct-message-arrow-connections").toggleClass("down");
    });

    $(document).on('click', '.toggle-handler', function() {
      var target = $(this).data('target');
      $(target).toggleClass('collapse'); // Replace 'collapse' with the appropriate class you want to toggle
    });

    $(document).on('click', '.direct-message-arrow', function() {
      $(this).toggleClass("down");
    });

    $(".message-text").click(function() {
      $(".direct-message-arrow").toggleClass("down");
    });

    $(".channel-arrow").click(function() {
      $(this).toggleClass("down");
    });

    $(".channel-text").click(function() {
      $(".channel-arrow").toggleClass("down");
    });

    $(".company-arrow").click(function() {
      $(this).toggleClass("down");
    });

    $(".company-text").click(function() {
      $(".company-arrow").toggleClass("down");
    });
  },

  checkMessageExists() {
    const searchParams = new URLSearchParams(window.location.search);
    const conversation_id = searchParams.get('conversation_id');
    const messageText = searchParams.get('message');

    if (!messageText) return;

    $.ajax({
        url: 'conversation_messages',
        method: 'get',
        dataType: 'json',
        data: { conversation_id: conversation_id },
        success: function(data) {
            const messages = data.messages.data.map(message => {
                return message.text.replace(/\\n|\n\n|\n|_/g, '').replace(/ /g, '');
            });

            const processedMessageText = decodeURIComponent(messageText)
                .replace(/\\n|\n\n|\n|_/g, '')
                .replace(/ /g, '');

            if (!messages.includes(processedMessageText)) {
                $('#notify_deleted_modal').modal('show');
            }
        }
    });
  },

  enterpriseDropdown() {
    $(".enterprise_bar_dropdown").change(function(e) {
      var selectedOption = $(this).find("option:selected");
      var completeHash = JSON.parse(selectedOption.attr("data"));
      var url = window.location.href;
      url = url.replace(/\?enterprise_company_id=.*$|\&enterprise_company_id=.*$|\?conversation_id=.*$|\&conversation_id=.*$/, '');
      url += "?conversation_id=" + completeHash['conversation_id'];
      var newUrl = url + "&enterprise_company_id=" + completeHash['id'];
      window.location.href = newUrl;
    });
  },

  enterpriseMemberDropdown() {
    $(".enterprise_member_dropdown").change(function(e) {
      var selectedEnterprise = $(".enterprise_bar_dropdown").find("option:selected")
      var selectedMember = $(this).find("option:selected")
      var completeHash = JSON.parse(selectedEnterprise.attr("data"));
      var memberHash = JSON.parse(selectedMember.attr("data"));
      var url = window.location.href;
      url = url.replace(/\?enterprise_company_id=.*$|\&enterprise_company_id=.*$|\?conversation_id=.*$|\&conversation_id=.*$/, '');
      url += "?conversation_id=" + memberHash['conversation_id'];
      var newUrl = url + "&enterprise_company_id=" + completeHash['id'] + "&member_id=" + memberHash['id'];
      console.log(newUrl)
      window.location.href = newUrl;
    });
  },

  loadChat() {
    $(".load_chat").click(function(e) {
      var path = $(this).data('path')
      var conversationId = $(this).data('conversation-id')
      const self = this;
      var chatUser=document.getElementById('navbar-heading-value')
      var companyLink = $(this).data('company-link')
      var NavbarUserName = $(this).data('nav-user-name')
      var connectionName = $(this).data('connection-name')
      var newHref = "companies/" + companyLink
      var selectedCompanyId = $(this).data('connection-id')
      var selectedOption = $(".enterprise_bar_dropdown").find("option:selected");
      var enterpriseCompanyId, completeHash, memberId;
      var hideChatValue = document.getElementById('chat-module').getAttribute('hide-chat');
      var selectedMember = $(".enterprise_member_dropdown").find("option:selected")
      if (selectedMember.attr("data") !== undefined) {
        var memberHash = JSON.parse(selectedMember.attr("data"));
        memberId = memberHash['id']
      }

      if (selectedOption.length > 0) {
        completeHash = JSON.parse(selectedOption.attr("data"));
        if(completeHash["id"] === "1")
          enterpriseCompanyId
        else
          enterpriseCompanyId = completeHash["id"]
      }
      $.ajax({
        type: "GET",
        url: '/talkjs_conversation_uuid',
        data: {
          uuid: $(this).data('uuid'),
          selected_company_id: selectedCompanyId,
          enterprise_company_id: enterpriseCompanyId,
          selected_member_id: memberId
          },
        dataType: 'json',
        success: function(data) {
          var conversationId = data.conversation_id
          if (selectedCompanyId == 1){
            $(`#connectionDropDown-${selectedCompanyId}`).removeClass('unread-message-badge')
            var elements = document.querySelectorAll(`#unreadMessageBadge-${conversationId}`);
            elements.forEach(function(element) {
              element.classList.remove('unread-message-badge');
            });
          }
          else{
            $(`#unreadMessageBadge-${conversationId}`).removeClass('unread-message-badge')
            $(`#connectionDropDown-${selectedCompanyId}`).removeClass('unread-message-badge')
          }
          // if($(`#chatContainer-${selectedCompanyId}`).find('div.unread-message-badge').length === 0){
          //   $(`#connectionDropDown-${selectedCompanyId}`).removeClass('unread-message-badge')
          // }
          window.inbox.destroy();
          window.inbox = talkSession.createInbox({
            showFeedHeader: true,
            showTranslationToggle: true,
            showChatHeader: false,
            messageField:{placeholder: 'Send a message to ' + NavbarUserName}
          });
          if (hideChatValue == "true") {
            inbox.messageField.setVisible(false);
          }

          if (conversationId) {
            self.unReadConversation
            window.inbox.select(conversationId);
          }
          window.inbox.mount(document.getElementById("talkjs-container"));
          var url = window.location.href;
          var updatedUrl = url.split('?')[0] + '?conversation_id=' + conversationId + '&selected_company_id=' + selectedCompanyId + window.location.hash;
          window.history.pushState({ path: updatedUrl }, '', updatedUrl);
          $('#navbar-heading-value').text(connectionName+ " - "+ NavbarUserName);
          $('#navbar-heading-value').parent().attr('href', newHref);

          }
      });

    });
  },

  directMessagingUsers() {
    $('#direct_message_user').click(function(event) {
      var id = event.currentTarget.dataset.conversationId
      window.history.replaceState(null, null, `?conversation_id=${id}`);
    });
  },

  currentConversationID() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    return url.searchParams.get("conversation_id");
  },
  reloadHtmlPanel() {
    const self = this;
    if (window.htmlPanel) {
      window.htmlPanel.destroy();
    }

    setTimeout(function() {
      self.loadHtmlPanel();
    }, 500);
  },

  loadHtmlPanel() {
    var selectedConversationId = this.currentConversationID();
    var videoParams = {
      conversation_id: selectedConversationId,
      user_uuid: current_user_uuid,
      company_uuid: current_company_uuid
    }
    var videoUrlParams = $.param(videoParams);
    window.inbox.createHtmlPanel({
      height: 72,
      url: "/video_calls?" + videoUrlParams,
      show: true
    }).then(function(result) {
      window.htmlPanel = result;
    });
  },

  desktopNotification(isEnabled) {
    window.talkSession.setDesktopNotificationEnabled(isEnabled, {
      alertOnFailure: true
    }).then(function(f) {
      $.ajax({
        url: `desktop_notification`,
        data: {
          'notification': isEnabled
        },
        dataType: 'json',
        success: function(data) {
          userData = data;
        }
      });
    });
  },

  updateState(id){
    var connectionId = $(`#unreadMessageBadge-${id}`).attr('data-connection')
    var connectionDropDown = $(`#connection-${connectionId}`)
    var supportConversationId = $('.kwayga-support').data('conversation-id')
    connectionDropDown.show();
    if (window.location.href.includes(id)){
      $(`#unreadMessageBadge-${id}`).removeClass('unread-message-badge')
      $(`#connectionDropDown-${connectionId}`).removeClass('unread-message-badge')
    }
    else if((supportConversationId === id)) {
        $(`#connectionDropDown-${connectionId}`).addClass('unread-message-badge')
        $('.conversation-user__list-drop-down:gt(2)').show();
        var elements = document.querySelectorAll(`#unreadMessageBadge-${id}`);
        elements.forEach(function(element) {
          element.classList.add('unread-message-badge');
        });
        $('.user-chat-connection-list').prepend(connectionDropDown)
      }
    else{
      $('.conversation-user__list-drop-down:gt(2)').show();
      $(`#unreadMessageBadge-${id}`).addClass('unread-message-badge')
      $(`#connectionDropDown-${connectionId}`).addClass('unread-message-badge')
      $('.user-chat-connection-list').prepend(connectionDropDown)
    }
  },

  sidebarToggle(){
    if ($('.conversation-user__list').length > 2) {
      $('.conversation-user__list:gt(2)').hide();
      $('.show-more').show();
    }

    if ($('.conversation-user__list-drop-down').length > 2) {
      $('.conversation-user__list-drop-down:gt(2)').hide();
      $('.show-more').show();
    }

    if ($('.conversation-group__list').length > 0) {
      $('.conversation-group__list:gt(0)').hide();
      $('.show-more-groups').show();
    }

    if(!$('.session-c-chat').length > 0) {
      if ($('.conversation-company__list').length > 2) {
        $('.conversation-company__list:gt(2)').hide();
        $('.show-more-company-chats').show();
      }
    }
  },

  unReadConversation(){
    let container = ''
    const self = this;
    window.talkSession.unreads.on("change", function(unreadConversations) {
      if(talk_me.id === current_selected_company_admin_id){
        var counter = unreadConversations.length === 0 || current_company_name === 'Kwayga' ? '' : unreadConversations.length
        $('#navbar__message-count').children().text(counter)
        $.each(unreadConversations.reverse(), function(key, value) {
          self.updateState(value.lastMessage.conversation.id)
        })
        self.sidebarToggle()
      }
      else{
        $.ajax({
          type: "GET",
          url: '/unread_chats',
          data: {
            uuid: talk_me.id
            },
          dataType: 'json',
          success: function(data) {
            var unreadChats = data.unread_chats;
            var counter = unreadChats.length === 0 || current_company_name === 'Kwayga' ? '' : unreadChats.length
            $('#navbar__message-count').children().text(counter)
            $.each(unreadChats.reverse(), function(key, value) {
              self.updateState(value.id)
            })
            self.sidebarToggle()
          }
         })
      }
    });
  },

  load() {
    const self = this;
    Talk.ready.then(
      function() {
        talk_me['locale'] = getUserLocale();
        var me = new Talk.User(talk_me);
        window.talkSession = new Talk.Session({
          appId: talk_app_id,
          me: me,
          signature: talkjs_signature
        });
        var chatUser=document.getElementById('navbar-heading-value');
        var selectedCompanyId = document.getElementById('company-data').getAttribute('data-company-id')
        window.inbox = talkSession.createInbox({
          showFeedHeader: true,
          showTranslationToggle: true,
          showChatHeader: false,
          messageField: {
            placeholder: 'Send a message to ' + chatUser.textContent
          },
        });
        var hideChatValue = document.getElementById('chat-module').getAttribute('hide-chat');

        if (hideChatValue == "true") {
          inbox.messageField.setVisible(false);
        }

        inbox.on("conversationSelected", function(e) {
          $('#video_conversation_id').val(e.conversation.id);
          window.history.pushState({
              conversationID: e.conversation.id
            },
            document.title,
            "/messaging?conversation_id=" + e.conversation.id
          );
          self.reloadHtmlPanel();
        });

        var selectedConversationId = self.currentConversationID();
        if (selectedConversationId) {
          inbox.select(selectedConversationId);
        }

        inbox.mount(document.getElementById("talkjs-container"));
        self.unReadConversation();
        self.loadHtmlPanel();
      }
    );
  },

  SearchUser() {
    $(".inputvalue").keyup(function(event) {
      var inputSearch, filter, companies_list, length, a, i, txtValue, value;
      inputSearch = document.getElementById('companyInput');
      filter = inputSearch.value.toUpperCase();
      companies_list = document.getElementById("company-search");
      length = companies_list.getElementsByTagName('div').length;
      a = companies_list.getElementsByTagName('div');
      for (i = 0; i < length; i++) {
        value = a[i].textContent;
        if (!(value.toUpperCase().indexOf(filter) > -1)) {
          a[i].classList.remove('d-flex');
          a[i].classList.add('hidden');
        } else {
          a[i].classList.add('d-flex');
        }
      }
    });
  },

  connectionsSearch() {
    $(".messagevalue").keyup(function(event) {
        var inputSearch = document.getElementById('connectionsInput');
        var filter = inputSearch.value.trim().toUpperCase();

        if (event.which === 8 && inputSearch.value.length === 0) {
            $('#messaging-search-users').html("");
        } else {
            $('#messaging-search-users').html("");
            TalkMessaging.fetchMessageConnections()
            var a = document.getElementById("messaging-search-users").getElementsByTagName('a');
            for (var i = 0; i < a.length; i++) {
              var value = a[i].textContent.split(" - ")[1];
              if (!(value.toUpperCase().includes(filter))) {
                a[i].classList.add('hidden');
              } else {
                a[i].classList.remove('hidden');
              }
            }
        }
    });
  },

  fetchMessageConnections() {
    var count = 0;
    var userData = {};
    if (count === 0) {
        $.ajax({
            url: 'search_message_connections',
            dataType: 'json',
            async: false,
            success: function(data) {
                userData = data;
            }
        });
        count = 1;
    }

    TalkMessaging.createUserRows(userData)
  },

  createUserRows(userData) {
      var keys_array = Object.keys(userData);
      var wrapper = document.getElementById('messaging-search-users');
      for (var i=0; i< keys_array.length; i++) {
        var users_array_length = userData[keys_array[i]].length;
        for (var j= 0; j< users_array_length-1; j++){
          var user_row = document.createElement('a');
          user_row.href = "talkjs_conversation_uuid?uuid="+userData[keys_array[i]][j].uuid+"&selected_company_id="+keys_array[i];
          user_row.className = 'entry panel row';
          user_row.innerHTML += userData[keys_array[i]][j].first_name + " ";
          user_row.innerHTML += userData[keys_array[i]][j].last_name + " - "
          user_row.innerHTML += userData[keys_array[i]][users_array_length-1];
          user_row.style.color = "black";
          user_row.style.margin = "2px";
          user_row.classList.add('hidden');
          wrapper.appendChild(user_row);
        }
      }
  },

  connectedCompaniesSearch() {
    $(".company-modal__chat").click(function(event) {
      var company_id = event.currentTarget.dataset.id
      var userData = {}
      $.ajax({
        url: `select-users`,
        data: {
          'id': company_id
        },
        dataType: 'json',
        async: false,
        success: function(data) {
          userData = data;
        }
      });
      var wrapper = document.getElementById('company_users_chat');
      $('#company_users_chat').html("");
      for (var j = 0; j < userData.length; j++) {
        var user_row = document.createElement('a');
        user_row.href = "talkjs_conversation_uuid?uuid=" + userData[j].uuid + "&selected_company_id=" + company_id;
        user_row.className = 'entry panel row';
        user_row.innerHTML += userData[j].first_name + " ";
        user_row.innerHTML += userData[j].last_name;
        if (userData[j].role_name === 'admin') {
          user_row.innerHTML += " (Admin)";
        }
        user_row.style.color = "black";
        user_row.style.margin = "2px";
        wrapper.appendChild(user_row);
      }
      $("#select_company").modal('hide');
      $("#user_selection").modal('show');
    });

    $(".back-modal").click(function(event) {
      $("#user_selection").modal('hide');
    });
  },

  groupFormValidation()
  {
   $('.group-name').keyup(function(event) {
    $('.name_limit').text(`${(80 - event.currentTarget.value.length)}/ 80 characters.`)
    if (event.currentTarget.value.length == 80) {
      $('.limit_message').text("Group Name can't be longer than 80 characters")
      $('#sent_invite_btn').attr('disabled', 'disabled');
    }
    if (event.currentTarget.value.length < 80) {
      $('.limit_message').text('')
      $('#sent_invite_btn').removeAttr('disabled');
    }
  });
  }
};
